import { useForm } from "react-hook-form";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
// import { enqueueSnackbar } from "notistack";
import { fDate1 } from "utils/formatTime";
import useModal from "hooks/useModal";
import { useState } from "react";

export default function useUILogic() {
  const methods = useForm();
  const [loadedImage, setLoadedImage] = useState(0);

  // fetch data
  const { data: campaignData } = useAxios({
    url: Panel_API_Routes.resources.campaignRegister,
    loadOnStart: true,
  });

  // submit
  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.campaign.register,
    method: "post",
  });

  const [openSuccess, toggleSuccess] = useModal();

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          birth_date: fDate1(params.birth_date),
          eng_lang_level: params.eng_lang_level?.value,
          gender: params.gender?.value,
          specialty: params.specialty?.value,
          q5: params.q5?.value,
        },
      };

      const res = await mutate({ data });

      toggleSuccess();
      // enqueueSnackbar(
      //   "لقد تمت الخطوة الأولى في مسيرة الانجاز, سيتم التواصل معكم قريباً وتحديد موعد المقابلة , شكراً لكم",
      //   { variant: "success" }
      // );

      methods.reset();
      setTimeout(() => {
        methods.clearErrors("mobile");
      }, 0);
    } catch (error) {}
  };

  return {
    methods,
    loadedImage,
    setLoadedImage,
    //
    campaignData,
    //
    openSuccess,
    toggleSuccess,
    isLoading,
    onSubmit,
  };
}
