import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Navigate } from "react-router-dom";

export default function ErrorBoundary({ children }) {
  function ErrorFallback({ error, resetErrorBoundary }) {
    return null; // FIXME

    // TODO call api

    resetErrorBoundary();

    return <Navigate to="/error" />;
  }

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      {children}
    </ReactErrorBoundary>
  );
}
