import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic({ toggle, formMode, methods }) {
  const { profileData, refetchProfile } = useOutletContext();

  // add and edit
  const { isLoading: isLoadingAdd, mutate: mutateAdd } = useAxios({
    url: Panel_API_Routes.profile.certificates.add,
    method: "post",
  });

  const { isLoading: isLoadingEdit, mutate: mutateEdit } = useAxios({
    url: Panel_API_Routes.profile.certificates.edit,
    method: "put",
  });

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          url: params.url?.fileURL,
        },
      };

      if (formMode === "add") {
        await mutateAdd({ data });
        enqueueSnackbar("Added successfully", { variant: "success" });
      } else {
        await mutateEdit({ data });
        enqueueSnackbar("Edited successfully", { variant: "success" });
      }
      toggle();
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    isLoading: isLoadingAdd || isLoadingEdit,
    onSubmit,
  };
}
