import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";
import { fDate1 } from "utils/formatTime";

export default function useUILogic({ toggle, formMode }) {
  const { profileData, refetchProfile } = useOutletContext();

  // add and edit
  const { isLoading: isLoadingAdd, mutate: mutateAdd } = useAxios({
    url: Panel_API_Routes.profile.workExperiences.add,
    method: "post",
  });

  const { isLoading: isLoadingEdit, mutate: mutateEdit } = useAxios({
    url: Panel_API_Routes.profile.workExperiences.edit,
    method: "put",
  });

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          start_date: fDate1(params.start_date),
          end_date: !params.currently_working ? fDate1(params.end_date) : null,
        },
      };

      if (formMode === "add") {
        await mutateAdd({ data });
        enqueueSnackbar("Added successfully", { variant: "success" });
      } else {
        await mutateEdit({ data });
        enqueueSnackbar("Edited successfully", { variant: "success" });
      }
      toggle();
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    isLoading: isLoadingAdd || isLoadingEdit,
    onSubmit,
  };
}
