import { styled } from "@mui/material/styles";

export const StyledBox = styled("nav")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: "fixed",
  zIndex: theme.zIndex.appBar,
  bottom: 0,
  left: 0,
  right: 0,
  height: theme.mixins.navbar.height_mobile,
}));

export const StyledList = styled("ul")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",

  li: {
    flex: 1,
    height: "100%",

    ">a , >div": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#EDEDED",
      transition: theme.transitions.create(),
      fontSize: 10,
      position: "relative",

      "&:before": {
        content: '""',
        position: "absolute",
        left: "15%",
        bottom: 2,
        width: "70%",
        height: 3,
        borderRadius: 4,
        backgroundColor: "#ffffff",
        transition: theme.transitions.create(),
        opacity: 0,
      },

      "& [data-title]": {
        marginTop: 4,
        lineHeight: "10px",
      },

      "img.svg-box": {
        display: "none",
      },
      "&.active": {
        color: "#ffffff",
        fontWeight: theme.typography.fontWeightMedium,
        "&:before": {
          opacity: 1,
        },
        "img.svg-box": {
          display: "block",
        },
        "span.svg-box": {
          display: "none",
        },
      },
    },
  },
}));
