import { useEffect, useState } from "react";
import { useFormContext, useController } from "react-hook-form";
import useModal from "hooks/useModal";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic({ name, rules }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: "" });

  const [status, setStatus] = useState("enter_phone"); // enter_phone , sent , successful , time_out , confirmed

  const [inputValue, setInputValue] = useState("");

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.user.requestMobileVerification,
    method: "post",
  });

  const handleSend = async () => {
    if (inputValue) {
      try {
        const data = {
          params: {
            mobile_number: inputValue,
          },
        };
        await mutate({ data });
        setStatus("sent");
      } catch (error) {}
    }
  };

  // only change rhk value when status is confirmed
  useEffect(() => {
    if (status === "confirmed") {
      field.onChange(inputValue);
    }
  }, [status]);

  // set inputValue from default value
  useEffect(() => {
    if (field.value) {
      setInputValue(field.value);
      setStatus("confirmed");
    }
  }, [field.value]);

  const [openModal, toggleModal] = useModal();

  return {
    ref,
    field,
    error,
    status,
    setStatus,
    inputValue,
    setInputValue,
    isLoading,
    handleSend,
    openModal,
    toggleModal,
  };
}
