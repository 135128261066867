import React from "react";
import { Box, Divider, IconButton, Rating, Typography } from "@mui/material";
import SvgBox from "components/svg-box";
import AddNewSkill from "./components/add-new-skill";
import useUILogic from "./logic";
import { StyledSkills } from "./styles";

export default function SkillsSection() {
  const { groupedSkills, handleDelete, isLoadingDelete } = useUILogic();

  return (
    <div>
      <Typography
        color="gray_iron.800"
        fontSize={18}
        fontWeight="medium"
        mb={3}
      >
        Skills
      </Typography>

      <Typography color="gray_iron.600">
        What skills do you know about softskills ?
      </Typography>

      <AddNewSkill />

      {groupedSkills?.length ? (
        groupedSkills?.map(({ group_anme, options }, i) => (
          <React.Fragment key={group_anme}>
            <StyledSkills>
              <Typography
                color="gray_iron.600"
                mb="12px"
                textTransform="capitalize"
              >
                {group_anme}
              </Typography>
              <div>
                {options.map(({ id, rate, title }) => (
                  <div key={id}>
                    <span>{title}</span>
                    <Rating value={rate} readOnly />
                    <IconButton
                      onClick={() => handleDelete(id)}
                      disabled={isLoadingDelete}
                    >
                      <SvgBox src="/assets/icons/common/ic_close.svg" />
                    </IconButton>
                  </div>
                ))}
              </div>
            </StyledSkills>
            {i !== groupedSkills.length - 1 && (
              <Box
                sx={{
                  mt: { xs: 1, md: 0 },
                  mb: { xs: 3, md: 2 },
                }}
              >
                <Divider sx={{ display: { sm: "none" } }} />
              </Box>
            )}
          </React.Fragment>
        ))
      ) : (
        <Box
          sx={{
            color: "gray.main",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 2,
          }}
        >
          There is no skill to show
        </Box>
      )}
    </div>
  );
}
