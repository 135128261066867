import { useEffect, useState } from "react";
import { useInterval } from "hooks/useInterval";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic({ status, setStatus }) {
  const [otp, setOtp] = useState("");

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.user.confirmMobile,
    method: "post",
  });

  const onChange = async (value) => {
    setOtp(value);
    if (value?.length === 4) {
      try {
        const data = {
          params: {
            pin: value,
          },
        };
        await mutate({ data });
        setStatus("successful");
        setTimeout(() => setStatus("confirmed"), 3000);
      } catch (error) {}
    }
  };

  const [countdown, setCountdown] = useState(120);

  useInterval(
    () => {
      if (countdown === 0) {
        setStatus("time_out");
      } else {
        setCountdown(countdown - 1);
      }
    },
    status === "sent" ? 1000 : null
  );

  useEffect(() => {
    if (status === "sent") {
      setCountdown(120);
    }
  }, [status]);

  return {
    otp,
    isLoading,
    onChange,
    countdown,
  };
}
