import useAxios from "hooks/useAxios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Panel_API_Routes, Panel_Routes } from "routes/path";
import { logout } from "store/globalStore";

export default function useUILogic() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useAxios({
    url: Panel_API_Routes.user.startup,
    method: "get",
  });

  const handleLoad = useCallback(async () => {
    setIsLoading(true);
    try {
      await mutate(); // response will set in axiosLib interceptor
    } catch (error) {
      logout();
      navigate(Panel_Routes.auth.login);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return {
    isLoading,
  };
}
