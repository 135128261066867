import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useTabs from "hooks/useTabs";
import useAxios from "hooks/useAxios";
import { Panel_Routes, Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const [currentTab, onChangeTab] = useTabs("General");
  const location = useLocation();

  const tabs = useMemo(
    () => [
      {
        label: "General",
        value: "General",
        to: Panel_Routes.profile.general,
      },
      {
        label: "Career info",
        value: "Career info",
        to: Panel_Routes.profile.careerInfo,
      },
      {
        label: "Skills",
        value: "Skills",
        to: Panel_Routes.profile.interpersonalSkills,
      },
    ],
    []
  );

  useEffect(() => {
    const tab = tabs.find(({ to }) => to === location.pathname);
    onChangeTab(null, tab?.value || "General");
  }, [location, tabs]);

  // startup (for sidemenu)
  const { mutate: mutateStartup } = useAxios({
    url: Panel_API_Routes.user.startup,
  });

  // fetching data
  const [isLoading, setIsLoading] = useState(false);

  const {
    error,
    data: profileData,
    mutate,
  } = useAxios({
    url: Panel_API_Routes.profile.getInfo,
  });

  const refetchProfile = async ({
    showLoading = true,
    callStartup = false,
  } = {}) => {
    if (showLoading) setIsLoading(true);
    try {
      await mutate();
    } catch (error) {}
    setIsLoading(false);

    if (callStartup) {
      try {
        await mutateStartup();
      } catch (error) {}
    }
  };

  useEffect(() => {
    refetchProfile();
  }, []);

  return {
    currentTab,
    tabs,
    //
    isLoading,
    profileData,
    error,
    refetchProfile,
  };
}
