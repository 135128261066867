import { styled } from "@mui/material/styles";

export const StyledBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,

  ".svg-box": {
    marginTop: -34,
    width: 123,
    height: 123,
    color: "#ffffff",

    [theme.breakpoints.down("sm")]: {
      color: "#7FF1CF",
    },
  },

  ">a": {
    width: "100%",
    marginTop: "auto",
    button: {
      width: "100%",
    },
  },
}));
