import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import Modal from "components/modal";
import inputComponent from "./components/input-component";
import OtpInput from "./components/otp-input";
import useUILogic from "./logic";
import { StyledPhone } from "./styles";

export default function RHKPhone({ name, rules, ...other }) {
  const {
    ref,
    field,
    error,
    status,
    setStatus,
    inputValue,
    setInputValue,
    isLoading,
    handleSend,
    openModal,
    toggleModal,
  } = useUILogic({
    name,
    rules,
  });

  return (
    <>
      <StyledPhone status={status}>
        <TextField
          {...field}
          inputRef={ref}
          value={inputValue}
          onChange={(v) => {
            setInputValue(v);
            setStatus("enter_phone");
            field.onChange("");
          }}
          error={!!error}
          helperText={
            error
              ? error.message
              : status === "time_out"
              ? "* Time out ! please resend code again"
              : ""
          }
          onClick={() => {
            if (status === "confirmed") toggleModal();
          }}
          InputProps={{
            inputComponent,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : status === "enter_phone" ? (
                  <div onClick={handleSend}>Send Code</div>
                ) : status === "time_out" ? (
                  <div onClick={handleSend}>Resend Code</div>
                ) : status === "successful" || status === "confirmed" ? (
                  <div>Confirmed</div>
                ) : (
                  ""
                )}
              </InputAdornment>
            ),
          }}
          {...other}
        />

        {status !== "confirmed" && (
          <OtpInput status={status} setStatus={setStatus} />
        )}
      </StyledPhone>

      <Modal
        open={openModal}
        handleClose={() => toggleModal()}
        title="Change Phone Number"
        maxWidth="xs"
      >
        Are you sure you want to change phone number?
        <Box sx={{ display: "flex", mt: 4 }}>
          <Button
            variant="outlined"
            color="gray"
            sx={{ flexGrow: 1, margin: "0 8px", color: "gray_iron.800" }}
            onClick={() => toggleModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ flexGrow: 1, margin: "0 8px" }}
            onClick={() => {
              setInputValue("");
              setStatus("enter_phone");
              field.onChange("");
              toggleModal();
            }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </>
  );
}

RHKPhone.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
