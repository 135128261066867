import { styled } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";

export const StyledAddNew = styled(ButtonBase)(({ theme }) => ({
  marginTop: 24,
  padding: "14px",
  border: `1px dashed ${theme.palette.primary[500]}`,
  borderRadius: 16,
  fontSize: 14,
  color: theme.palette.primary.main,
  width: "100%",
  ".svg-box": {
    color: "#000000",
    marginRight: 12,
  },
}));
