import { CircularProgress } from "@mui/material";
import { StyledCountdown } from "./styles";

export default function CircularCountdown({ countdown, total, content }) {
  const percent = (countdown * 100) / total;

  const minute = Math.floor(countdown / 60);
  const second = countdown - minute * 60;

  return (
    <StyledCountdown>
      <CircularProgress
        variant="determinate"
        value={100}
        size={46}
        thickness={2}
        data-full-circle
      />
      <CircularProgress
        variant="determinate"
        value={percent}
        size={46}
        thickness={2}
      />
      <div>{content ?? `${minute}:${second}`}</div>
    </StyledCountdown>
  );
}
