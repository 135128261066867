import { styled } from "@mui/material/styles";

export const StyledPhone = styled("div")(({ theme, status }) => ({
  display: "flex",

  ">.MuiFormControl-root": {
    width: "50%",
    ".MuiInputBase-root": {
      cursor: status === "confirmed" ? "pointer" : "auto",
      ".react-tel-input": {
        width: "100%",
        input: {
          width: "calc(100% - 28px)",
          cursor: status === "confirmed" ? "pointer" : "auto",
        },
        ".special-label , .flag-dropdown": {
          display: "none",
        },
      },
      ".MuiInputAdornment-positionEnd": {
        fontSize: 14,
        lineHeight: "17px",
        color:
          status === "time_out"
            ? theme.palette.info.main
            : theme.palette.primary.main,
        ">div": {
          cursor: "pointer",
        },
      },
    },
    ".MuiFormHelperText-root": {
      color: theme.palette.primary.main,
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
