import { Outlet } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Header from "./header";
import PanelAlert from "./panel-alert";
import Nav from "./nav";
import useUILogic from "./logic";
import { StyledLoading, StyledRoot } from "./styles";

export default function DashboardLayout() {
  const { isLoading } = useUILogic();
  // const isLoading = false;

  return (
    <>
      <StyledRoot>
        <Header />
        <Nav />
        {isLoading ? (
          <StyledLoading>
            <CircularProgress size={100} />
          </StyledLoading>
        ) : (
          <main>
            <div>
              <PanelAlert />
              <Outlet />
            </div>
          </main>
        )}
      </StyledRoot>
    </>
  );
}
