import { styled, alpha } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledSpin = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "inherit",

  ">.MuiBackdrop-root": {
    position: "absolute",
    borderRadius: "inherit",
    zIndex: 1,
  },
}));
