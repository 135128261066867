import { styled } from "@mui/material/styles";

export const StyledForm = styled("form")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  button: {
    width: "100%",
    marginTop: "auto",
  },
}));
