import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { InputLabel, TextField } from "@mui/material";
import { StyledWrapper } from "./styles";

export default function RHKTextField2({ name, rules, label, ...other }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: "" });

  return (
    <StyledWrapper>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <TextField
        {...field}
        id={field.name}
        inputRef={ref}
        error={!!error}
        helperText={error ? error?.message || "هذا الحقل مطلوب" : ""}
        {...other}
      />
    </StyledWrapper>
  );
}

RHKTextField2.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  label: PropTypes.node,
};
