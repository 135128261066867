export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
          ".MuiFormLabel-root": {
            color: "#9B959F",
            "&:not(.MuiInputLabel-shrink)": {
              transform: "translate(14px, 12px) scale(1)",
            },
            "&.Mui-disabled": {
              color: "#9B959F",
            },
          },
          ".MuiInputBase-root": {
            backgroundColor: "#ffffff",
            fontFamily: "inherit",
            input: {
              padding: "10.5px 14px",
              '&[type="email"]': {
                direction: "ltr",
              },
            },
            fieldset: {
              border: `1px solid #837A88`,
              borderRadius: 16,
            },
            "&.Mui-focused": {
              fieldset: {
                borderWidth: 1,
              },
              "&:not(.Mui-error)": {
                fieldset: {
                  borderColor: "#1FAE83",
                },
              },
            },
            "&.Mui-disabled": {
              backgroundColor: "#E2E2E2",
              fieldset: {
                borderColor: "#9B959F",
              },
            },
          },

          "&[data-size=large]": {
            ".MuiFormLabel-root": {
              "&:not(.MuiInputLabel-shrink)": {
                transform: "translate(14px, 18px) scale(1)",
              },
            },
            ".MuiInputBase-root": {
              input: {
                padding: "16.5px 14px",
              },
            },
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: "unset",
          whiteSpace: "unset",
          fontFamily: "inherit",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
  };
}
