import { styled, alpha } from "@mui/material/styles";

export const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 350,
  fontWeight: theme.typography.fontWeightMedium,

  ">strong": {
    padding: "18px 24px 0 24px",
    display: "block",
    fontSize: 18,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  ">p": {
    padding: "0 24px 18px",
    fontSize: 14,
    color: theme.palette.gray_iron[600],
    marginTop: 4,
  },
  ">div": {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "calc(100% - 48px)",
      height: 1,
      left: 24,
      top: 0,
      backgroundColor: theme.palette.gray_iron[100],
    },
  },
}));

export const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "14px 24px",
  cursor: "pointer",

  "&:not(:last-of-type)": {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "calc(100% - 48px)",
      height: 1,
      left: 24,
      bottom: 0,
      backgroundColor: theme.palette.gray_iron[100],
    },
  },

  ">.svg-box": {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    marginRight: 8,
    position: "relative",
    top: 2,
    color: theme.palette.primary.main,
  },

  ">div": {
    flexGrow: 1,

    ">strong": {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
    },
    ">p": {
      fontSize: 14,
      color: theme.palette.gray_iron[600],
      whiteSpace: "pre-wrap",
    },
    ">div": {
      marginTop: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 12,
      "&>:first-of-type": {
        color: theme.palette.gray_iron[400],
      },
      "&>:last-of-type": {
        color: theme.palette.primary.main,
      },
    },
  },

  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
}));
