import { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import SvgBox from "components/svg-box";
import { StyledDatePicker } from "./styles";

export default function RHKDatePicker({ name, rules, ...other }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  const [open, setOpen] = useState(false);

  return (
    <StyledDatePicker
      {...field}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      format="d MMM, yyyy"
      slots={{
        openPickerButton: () => (
          <>
            {field.value && (
              <SvgBox
                src="/assets/icons/common/ic_close.svg"
                sx={{ width: 18, height: 18, mr: "4px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  field.onChange(null);
                }}
              />
            )}
            <SvgBox
              src="/assets/icons/components/ic_calendar.svg"
              sx={{ width: 18, height: 18 }}
            />
          </>
        ),
      }}
      slotProps={{
        textField: {
          inputRef: ref,
          error: !!error,
          helperText: error ? error?.message || "* This field is required" : "",
          readOnly: true,
          onClick: () => !other.disabled && setOpen(!open),
        },
      }}
      {...other}
    />
  );
}

RHKDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
