import { Collapse, Popover } from "@mui/material";
import BlinkingBadge from "components/blinking-badge";
import NavLink from "../nav-link";
import useUILogic from "./logic";
import { StyledSubLink } from "./styles";

export default function NavLinkSubMenu({ path, sub_menues, wide, ...other }) {
  const { isCollapseOpen, anchorEl, handlePopoverOpen, handlePopoverClose } =
    useUILogic({ path });

  const renderMenu = sub_menues?.map(({ title, path, blinking_badge }) => (
    <StyledSubLink
      key={title}
      to={path}
      wide={wide}
      onClick={() => handlePopoverClose()}
    >
      <span data-cube />
      <span data-title>{title}</span>
      {blinking_badge && (
        <BlinkingBadge
          size={8}
          sx={{
            position: "absolute",
            right: wide ? 24 : -4,
          }}
        />
      )}
    </StyledSubLink>
  ));

  return (
    <div onMouseLeave={handlePopoverClose}>
      <NavLink
        path={path}
        wide={wide}
        onMouseEnter={handlePopoverOpen}
        {...other}
      />

      {wide ? (
        <Collapse in={isCollapseOpen} sx={{ padding: "0 10px 0 20px" }}>
          {renderMenu}
        </Collapse>
      ) : (
        <Popover
          sx={{
            pointerEvents: "none",
            ".MuiPopover-paper": {
              pointerEvents: "auto",
              bgcolor: "primary.700",
              p: "8px 0",
              border: "1px solid #ffffff",
              minWidth: "205px",
            },
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          disableScrollLock
        >
          {renderMenu}
        </Popover>
      )}
    </div>
  );
}
