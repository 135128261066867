import { useEffect, useReducer, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import { useGlobalStore } from "store/globalStore";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const user = useGlobalStore((state) => state.user);

  // get data
  const { error, data, isLoading } = useAxios({
    url: Panel_API_Routes.user.dashboard,
    loadOnStart: true,
  });

  // handle slider
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      loop: true,
      slides: {
        perView: 1,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 6000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.update();
      forceUpdate();
    }
  }, [data]);

  return {
    user,
    //
    error,
    data,
    isLoading,
    //
    loaded,
    sliderRef,
    instanceRef,
    currentSlide,
  };
}
