import { useCallback, useEffect, useReducer, useState } from "react";
import { axiosLib } from "lib/axios-lib";

function reducer(state, { type, payload }) {
  switch (type) {
    case "start_fetch":
      return { ...state, isLoading: true };
    case "success_fetch":
      return { isLoading: false, error: null, response: payload };
    case "fail_fetch":
      return { isLoading: false, error: payload, response: null };
  }
}

export default function useAxios({
  loadOnStart,
  onError, // firing error event if provided
  onSuccess, // firing success event if provided
  ...initConfigs // axios request configs
}) {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    error: null,
    response: null,
  });

  const mutate = useCallback(async (config) => {
    dispatch({ type: "start_fetch" });

    //
    try {
      const res = await axiosLib({
        ...initConfigs,
        ...config,
      });

      //
      dispatch({ type: "success_fetch", payload: res });
      onSuccess?.(res);
      return res;

      //
    } catch (error) {
      //
      dispatch({ type: "fail_fetch", payload: error });
      onError?.(error);
      throw error;
    }
  }, []);

  useEffect(() => {
    handleLoadOnStart();
  }, []);

  const handleLoadOnStart = async () => {
    if (loadOnStart) {
      try {
        await mutate();
      } catch (error) {}
    }
  };

  return {
    ...state, // isLoading , error , response

    // exporting response data seperately for convenience (success only)
    ...(state.response?.data
      ? state.response.data
      : {
          data: null,
          status: null,
          paginationData: null,
        }),

    mutate,
  };
}
