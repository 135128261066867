import { Helmet } from "react-helmet-async";
import { FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKTextField from "components/forms/rhk-text-field";
import RHKPassword from "components/forms/rhk-password";
import RHKCheckbox from "components/forms/rhk-checkbox";
import Image from "components/image";
import SvgBox from "components/svg-box";
import { LOGIN_PAGE_TITLE } from "lib/page-titles";
import { EMAIL_REGEX } from "lib/regex";
import { Panel_Routes } from "routes/path";
import useUILogic from "./logic";
import {
  StyledAuth,
  StyledForm,
  StyledFormWrapper,
  StyledImage,
} from "./styles";

export default function LoginPage() {
  const { methods, isLoading, onSubmit } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{LOGIN_PAGE_TITLE}</title>
      </Helmet>

      <StyledAuth>
        <StyledFormWrapper>
          <div>
            <div>
              <SvgBox src="/assets/logo.svg" component="svg" />
            </div>
            <Typography
              color="gray_iron.600"
              fontSize={{ xs: 32, md: 40 }}
              fontWeight="medium"
              mt={{ md: "105px" }}
            >
              Welcome Back
            </Typography>
            <Typography
              color="gray_iron.600"
              m={{ xs: "48px 0 32px", md: "78px 0 32px" }}
            >
              Enter your details below.
            </Typography>

            <FormProvider {...methods}>
              <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <RHKTextField
                      name="email"
                      label="Email Address"
                      type="email"
                      rules={{
                        required: true,
                        pattern: {
                          value: EMAIL_REGEX,
                          message: "* Email address format is not correct !",
                        },
                      }}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHKPassword
                      name="password"
                      label="Password"
                      rules={{ required: true }}
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHKCheckbox
                      name="remember"
                      label="Remember me"
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Link to={Panel_Routes.auth.forgetPassword}>
                      Forget Password?
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      sx={{ width: "100%" }}
                      size="large"
                    >
                      Login
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to={Panel_Routes.auth.register}>
                      <Button
                        variant="contained"
                        sx={{ width: "100%" }}
                        size="large"
                      >
                        Register
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </StyledForm>
            </FormProvider>
          </div>
        </StyledFormWrapper>

        <StyledImage>
          <Image
            src={`${process.env.PUBLIC_URL}/assets/images/auth-background.png`}
          />
          <div>
            <div />
          </div>
          <div>
            <SvgBox src="/assets/logo.svg" sx={{ width: 191, height: 75 }} />
            <p>
              Your Path to <span>Professional Growth</span> and Employment{" "}
              <strong>Success!</strong>
              <SvgBox src="/assets/icons/auth/ic_check-2.svg" />
            </p>
          </div>
        </StyledImage>
      </StyledAuth>
    </>
  );
}
