import { useState } from "react";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic({ setStep, savedData, setSavedData }) {
  const [otp, setOtp] = useState("");

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.forgetPasswordPin,
    method: "post",
  });

  const onChange = async (value) => {
    setOtp(value);
  };

  const onSend = async () => {
    if (otp?.length === 4) {
      try {
        const data = {
          params: {
            token: savedData?.pinToken,
            pin: otp,
          },
        };

        const res = await mutate({ data });
        setStep("reset");
        setSavedData((prev) => ({
          ...prev,
          passwordToken: res.data.data?.token,
        }));
      } catch (error) {}
    }
  };

  return {
    otp,
    isLoading,
    onChange,
    onSend,
  };
}
