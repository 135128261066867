import { styled } from "@mui/material/styles";

export const StyledUpload = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.gray[200]}`,
  borderRadius: 16,
  padding: "12px 40px",
  textAlign: "center",
  cursor: "pointer",

  ">span": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    width: 40,
    height: 40,
    backgroundColor: theme.palette.gray[50],
    margin: "0 auto",
    ">span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      width: 30,
      height: 30,
      backgroundColor: theme.palette.gray[100],
      ".svg-box": {
        width: 20,
        height: 20,
      },
    },
  },

  ">div": {
    color: theme.palette.gray[600],
    fontSize: 14,
    marginTop: 12,
    span: {
      fontSize: 16,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export const StyledFile = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 12,
  padding: "12px 12px 16px 12px",
  display: "flex",

  ">span": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    width: 36,
    height: 36,
    backgroundColor: theme.palette.primary[50],
    ">span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      width: 28,
      height: 28,
      backgroundColor: theme.palette.primary[100],
      ".svg-box": {
        width: 16,
        height: 16,
        color: theme.palette.primary[600],
      },
    },
  },

  ">div": {
    flexGrow: 1,
    margin: "8px 12px",
    color: theme.palette.gray[700],
    display: "flex",
    flexDirection: "column",
    ">span:last-of-type": {
      marginTop: 8,
      color: theme.palette.info.main,
    },
  },

  ">button": {
    width: 36,
    height: 36,
    ".svg-box": {
      width: 20,
      height: 20,
    },
  },
}));
