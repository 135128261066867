import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import SvgBox from "components/svg-box";
import Image from "components/image";
import { Panel_Routes } from "routes/path";
import { REGISTER_PAGE_TITLE } from "lib/page-titles";
import RegisterForm from "./components/register-form";
import RegisterPin from "./components/register-pin";
import useUILogic from "./logic";
import { StyledAuth, StyledFormWrapper, StyledImage } from "./styles";

export default function RegisterPage() {
  const { step, setStep, savedData, setSavedData } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{REGISTER_PAGE_TITLE}</title>
      </Helmet>

      <StyledAuth>
        <StyledFormWrapper>
          <div>
            <div>
              <SvgBox src="/assets/logo.svg" component="svg" />
            </div>

            {step === "form" ? (
              <RegisterForm setStep={setStep} setSavedData={setSavedData} />
            ) : step === "pin" ? (
              <RegisterPin savedData={savedData} />
            ) : null}

            <Typography color="primary.800" textAlign="center" mt={5}>
              I have an account.{" "}
              <Box
                component={Link}
                to={Panel_Routes.auth.login}
                sx={{ textDecoration: "underline" }}
              >
                sign in
              </Box>
            </Typography>
          </div>
        </StyledFormWrapper>

        <StyledImage>
          <Image
            src={`${process.env.PUBLIC_URL}/assets/images/auth-background.png`}
          />
          <div>
            <div />
          </div>
          <div>
            <SvgBox src="/assets/logo.svg" sx={{ width: 191, height: 75 }} />
            <p>
              Your Path to <span>Professional Growth</span> and Employment{" "}
              <strong>Success!</strong>
              <SvgBox src="/assets/icons/auth/ic_check-2.svg" />
            </p>
          </div>
        </StyledImage>
      </StyledAuth>
    </>
  );
}
