import { useState } from "react";

// ----------------------------------------------------------------------

export default function useModal() {
  const [open, setOpen] = useState(false);

  const toggle = (customValue) => setOpen(customValue ?? !open);

  return [open, toggle];
}
