export const LOGIN_PAGE_TITLE = "Login | Enjaz";
export const REGISTER_PAGE_TITLE = "Sign-Up | Enjaz";
export const FORGOT_PASSWORD_PAGE_TITLE = "Forgot Password | Enjaz";
export const CAMPAIGN_REGISTER_PAGE_TITLE = "Campaign Register| Enjaz";

export const ERROR_PAGE_TITLE = "Error | Enjaz";
export const ERROR_401_PAGE_TITLE = "401 Unauthorized | Enjaz";
export const ERROR_404_PAGE_TITLE = "404 Page Not Found | Enjaz";
export const ERROR_500_PAGE_TITLE = "500 Server Error | Enjaz";

export const DASHBOARD_PAGE_TITLE = "Dashboard | Enjaz";
export const PROFILE_PAGE_TITLE = "Profile | Enjaz";
export const WALLET_PAGE_TITLE = "Wallet | Enjaz";
export const SETTINGS_PAGE_TITLE = "Settings | Enjaz";
