import { FormProvider } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKTextField from "components/forms/rhk-text-field";
import RHKPassword from "components/forms/rhk-password";
import { EMAIL_REGEX } from "lib/regex";
import useUILogic from "./logic";

export default function RegisterForm({ setStep, setSavedData }) {
  const { methods, type, isLoading, onSubmit } = useUILogic({
    setStep,
    setSavedData,
  });

  return (
    <div>
      <Typography
        color="gray_iron.600"
        fontSize={{ xs: 32, md: 40 }}
        fontWeight="medium"
        mt={{ md: "72px" }}
      >
        Welcome
      </Typography>
      <Typography color="gray_iron.600" m={{ md: "40px 0 24px" }}>
        Register as {type}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Grid container flexDirection="column" rowSpacing={4}>
            <Grid item>
              <RHKTextField
                name="full_name"
                label="Full Name"
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <RHKTextField
                name="email"
                label="Email Address"
                type="email"
                rules={{
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "* Email address format is not correct !",
                  },
                }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <RHKPassword
                name="password"
                label="Password"
                rules={{ required: true, deps: ["confirm_password"] }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <RHKPassword
                name="confirm_password"
                label="Password again"
                rules={{
                  required: true,
                  validate: (value, formValues) =>
                    value === formValues.password ||
                    "* Confirm password not matched !",
                }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "100%" }}
              >
                Create account
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
}
