// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

const fontWeightLight = 105;
const fontWeightRegular = 177;
const fontWeightMedium = 249;
const fontWeightBold = 308;

const typography = {
  fontFamily: "'Codec Pro', sans-serif",
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
};

export default typography;
