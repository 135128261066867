import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useUILogic({ path }) {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(path)) {
      setIsCollapseOpen(true);
    } else {
      setIsCollapseOpen(false);
    }
  }, [location]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  return {
    isCollapseOpen,
    anchorEl,
    handlePopoverOpen,
    handlePopoverClose,
  };
}
