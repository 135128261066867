import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledMultiSelect = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: 16,

  ">div:last-of-type": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    ">div": {
      borderRadius: 16,
      padding: "4px 4px 2px 12px",
      border: `1px solid ${theme.palette.gray[300]}`,
      color: theme.palette.gray[700],
      display: "flex",
      alignItems: "center",
      margin: "12px 0 0 12px",
      button: {
        color: theme.palette.gray[400],
        marginLeft: 8,
        marginTop: -2,
        padding: 0,

        "&:disabled": {
          color: theme.palette.gray[200],
        },

        ".svg-box": {
          width: 14,
          height: 14,
        },
      },
    },
  },
}));
