import { useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import useAxios from "hooks/useAxios";
import useModal from "hooks/useModal";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // form
  const methods = useForm();
  const [formMode, setFormMode] = useState("add"); // add, edit
  const [openFormModal, toggleFormModal] = useModal();

  const onAddClick = () => {
    toggleFormModal();
    setFormMode("add");
    methods.reset({
      job_title: "",
      company_name: "",
      start_date: null,
      end_date: null,
      responsibility: "",
      currently_working: false,
    });
  };

  const onEditClick = (item) => {
    toggleFormModal();
    setFormMode("edit");
    methods.reset({
      id: item.id,
      job_title: item.job_title,
      company_name: item.company_name,
      start_date: item.start_date ? new Date(item.start_date) : null,
      end_date: item.end_date ? new Date(item.end_date) : null,
      responsibility: item.responsibility,
      currently_working: item.currently_working,
    });
  };

  // delete
  const [openDeleteModal, toggleDeleteModal] = useModal();
  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useAxios({
    url: Panel_API_Routes.profile.workExperiences.delete,
    method: "delete",
  });
  const onDelete = async () => {
    try {
      const data = {
        params: { id: openDeleteModal },
      };
      await mutateDelete({ data });
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      toggleDeleteModal();
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    work_experiences: profileData?.work_experiences,
    //
    methods,
    formMode,
    openFormModal,
    toggleFormModal,
    onAddClick,
    onEditClick,
    //
    openDeleteModal,
    toggleDeleteModal,
    onDelete,
    isLoadingDelete,
  };
}
