import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes, Panel_Routes } from "routes/path";

export default function useUILogic({ setStep, setSavedData }) {
  const methods = useForm();
  const location = useLocation();

  const type =
    location.pathname === Panel_Routes.auth.registerTutor ? "tutor" : "student";

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.register,
    method: "post",
  });

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          type,
        },
      };

      const res = await mutate({ data });
      setStep("pin");
      setSavedData({
        token: res.data.data?.token,
        email: params.email,
      });
    } catch (error) {}
  };

  return {
    methods,
    type,
    isLoading,
    onSubmit,
  };
}
