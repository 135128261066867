import PropTypes from "prop-types";
import { Popover } from "@mui/material";
import SvgBox from "components/svg-box";
import useUILogic from "./logic";
import { StyledContent, StyledRow } from "./styles";

export default function NotificationsPopover({
  notifsAnchorEl,
  handleCloseNotifs,
}) {
  const { notifications } = useUILogic();

  return (
    <Popover
      open={!!notifsAnchorEl}
      anchorEl={notifsAnchorEl}
      onClose={handleCloseNotifs}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{ sx: { mt: { xs: "9px", sm: "19px" } } }}
    >
      <StyledContent>
        <strong>Notifications</strong>
        <p>
          {notifications?.length
            ? `You have ${notifications?.length} unread messages !`
            : "There are no new messages !"}
        </p>

        {!!notifications?.length && (
          <div>
            {notifications?.map(({ icon, title, text, date }) => (
              <StyledRow key={title}>
                <SvgBox src={`/assets/icons/header/ic_notification.svg`} />

                <div>
                  <strong>{title}</strong>
                  <p>{text}</p>
                  <div>
                    <span>{date}</span>
                    <span>Read More</span>
                  </div>
                </div>
              </StyledRow>
            ))}
          </div>
        )}
      </StyledContent>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  notifsAnchorEl: PropTypes.object,
  handleCloseNotifs: PropTypes.func,
};
