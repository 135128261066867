import { styled } from "@mui/material/styles";

export const StyledAlert = styled("div")(({ theme }) => ({
  margin: "-8px 0 20px",
  display: "flex",
  alignItems: "center",
  color: "#E12237",
  backgroundColor: theme.palette.error[50],
  padding: "20px 24px",
  borderRadius: 16,

  ".svg-box": {
    marginRight: 16,
    minWidth: 24,
  },

  ">div": {
    display: "flex",
    ">strong": {
      fontWeight: theme.typography.fontWeightBold,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
}));
