import { Badge, Box, ButtonBase, IconButton, Toolbar } from "@mui/material";
import SvgBox from "components/svg-box";
import Image from "components/image";
import { fDate2 } from "utils/formatTime";
import useUILogic from "./logic";
import NotificationsPopover from "./components/notifications-popover";
import ProfilePopover from "./components/profile-popover";
import AlertPopover from "./components/alert-popover";
import {
  StyledAlert,
  StyledButtons,
  StyledDate,
  StyledProfile,
  StyledRoot,
  StyledSearch,
} from "./styles";

export default function Header() {
  const {
    user,
    notifications,
    fullName,
    //
    notifsAnchorEl,
    handleOpenNotifs,
    handleCloseNotifs,
    //
    profileAnchorEl,
    handleOpenProfile,
    handleCloseProfile,
    //
    alertAnchorEl,
    handleOpenAlert,
    handleCloseAlert,
  } = useUILogic();

  return (
    <>
      <StyledRoot>
        <Toolbar>
          <div>
            <Box
              sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}
            >
              <SvgBox
                src="/assets/logo.svg"
                component="img"
                sx={{ width: 68, height: 32 }}
              />
            </Box>

            <StyledDate>{fDate2(new Date())}</StyledDate>

            {/* <StyledSearch>
              <SvgBox src="/assets/icons/common/ic_search.svg" />
              <input type="search" placeholder="Search" />
              <SvgBox src="/assets/icons/common/ic_close.svg" />
            </StyledSearch>

            <StyledAlert>
              <IconButton onClick={handleOpenAlert}>
                <SvgBox
                  src="/assets/icons/header/ic_warning.svg"
                  component="img"
                />
              </IconButton>
              <span>System is going for upgrading for 2 days</span>
            </StyledAlert> */}
          </div>

          <div>
            <StyledButtons>
              <ButtonBase>
                <div>
                  <SvgBox
                    src={`/assets/icons/header/ic_learning_center.svg`}
                    component="img"
                  />
                </div>
              </ButtonBase>
              <ButtonBase onClick={handleOpenNotifs}>
                <div>
                  <Badge badgeContent={notifications?.length}>
                    <SvgBox
                      src={`/assets/icons/header/ic_bell${
                        notifications?.length ? "_fill" : ""
                      }.svg`}
                    />
                  </Badge>
                </div>
              </ButtonBase>
            </StyledButtons>

            <StyledProfile onClick={handleOpenProfile}>
              <div>
                <span>{fullName}</span>
                <div>
                  <span>{user?.type}</span>
                  <SvgBox src="/assets/icons/common/ic_polygon.svg" />
                </div>
              </div>
              <Image src={user?.avatar} type="avatar" />
            </StyledProfile>
          </div>
        </Toolbar>
      </StyledRoot>

      <NotificationsPopover
        notifsAnchorEl={notifsAnchorEl}
        handleCloseNotifs={handleCloseNotifs}
      />
      <ProfilePopover
        profileAnchorEl={profileAnchorEl}
        handleCloseProfile={handleCloseProfile}
      />
      <AlertPopover
        alertAnchorEl={alertAnchorEl}
        handleCloseAlert={handleCloseAlert}
      />
    </>
  );
}
