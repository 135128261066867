import { Navigate, useRoutes } from "react-router-dom";
import { Panel_Routes } from "./path";

import AuthGuard from "guards/auth-guard";

// layouts
import DashboardLayout from "layouts/dashboard";
import ProfileLayout from "layouts/profile";
import AuthLayout from "layouts/auth";

// error
import ErrorPage from "pages/error/errorPage";
import Page404 from "pages/error/page404";
import Page401 from "pages/error/page401";
import Page500 from "pages/error/page500";

// auth
import LoginPage from "pages/auth/login";
import RegisterPage from "pages/auth/register";
import ForgetPasswordPage from "pages/auth/forget-password";
import RegisterCampaignPage from "pages/auth/register-campaign";

// panel
import DashboardPage from "pages/panel/dashboard";
import SettingsPage from "pages/panel/settings";
import ProfileGeneralPage from "pages/panel/profile/general";
import ProfileCareerInfoPage from "pages/panel/profile/career-info";
import ProfileInterpersonalSkillsPage from "pages/panel/profile/interpersonal-skills";
import WalletPage from "pages/panel/wallet";

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={Panel_Routes.dashboard} replace />,
        },
        {
          path: Panel_Routes.dashboard,
          element: <DashboardPage />,
        },
        {
          element: <ProfileLayout />,
          children: [
            {
              path: Panel_Routes.profile.index,
              element: <Navigate to={Panel_Routes.profile.general} replace />,
            },
            {
              path: Panel_Routes.profile.general,
              element: <ProfileGeneralPage />,
            },
            {
              path: Panel_Routes.profile.careerInfo,
              element: <ProfileCareerInfoPage />,
            },
            {
              path: Panel_Routes.profile.interpersonalSkills,
              element: <ProfileInterpersonalSkillsPage />,
            },
          ],
        },
        {
          path: Panel_Routes.wallet,
          element: <WalletPage />,
        },
        {
          path: Panel_Routes.settings,
          element: <SettingsPage />,
        },

        { path: "401", element: <Page401 /> },
        { path: "500", element: <Page500 /> },
      ],
    },

    {
      element: <AuthLayout />,
      children: [
        {
          path: Panel_Routes.auth.login,
          element: <LoginPage />,
        },
        {
          path: Panel_Routes.auth.register,
          element: <RegisterPage />,
        },
        {
          path: Panel_Routes.auth.registerTutor,
          element: <RegisterPage />,
        },
        {
          path: Panel_Routes.auth.forgetPassword,
          element: <ForgetPasswordPage />,
        },
      ],
    },

    {
      path: Panel_Routes.campaign.register,
      element: <RegisterCampaignPage />,
    },

    { path: "error", element: <ErrorPage /> },
    { path: "*", element: <Page404 /> },
  ]);

  return routes;
}
