import { Fragment } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import PageWrapper from "components/page-wrapper";
import SvgBox from "components/svg-box";
import { WALLET_PAGE_TITLE } from "lib/page-titles";
import { fCurrency } from "utils/formatNumber";
import useUILogic from "./logic";
import { StyledTable } from "./styles";

export default function WalletPage() {
  const { error, data, isLoading } = useUILogic();

  return (
    <PageWrapper
      pageTitle={WALLET_PAGE_TITLE}
      title="Wallet"
      error={error}
      isLoading={isLoading}
    >
      <Card sx={{ p: { xs: "16px", md: "32px 32px 40px 32px" } }}>
        <Grid
          container
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "flex-end" }}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography color="gray.600" fontSize={14}>
              Current balance
            </Typography>
            <Typography
              color="gray.900"
              fontSize={30}
              fontWeight="medium"
              mt={1}
            >
              AED {fCurrency(String(data?.current_balance))}
            </Typography>
          </Grid>

          <Grid>
            <Button variant="contained" sx={{ mt: { xs: 2, md: 0 } }} disabled>
              Add to balance
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Typography
        color="primary.900"
        fontSize={20}
        fontWeight="medium"
        mt={{ xs: 2.5, md: 4 }}
        mb={{ xs: 2, md: 3 }}
      >
        transactions
      </Typography>

      <Card>
        <StyledTable>
          <strong>Number</strong>
          <strong>Date</strong>
          <strong>Transaction Number</strong>
          <strong>Amount</strong>
          <strong>Description</strong>
          {data?.transactions?.map((item) => (
            <Fragment key={item.number}>
              <div>
                <Typography fontWeight="medium" color="gray.900">
                  #{item.number}
                </Typography>
              </div>
              <div>
                <Typography fontWeight="medium">{item.date}</Typography>
              </div>
              <div>{item.transaction_number}</div>
              <div data-amount>
                <span>{item.amount}</span>
                <span>
                  <SvgBox src="/assets/icons/common/ic_arrow_up.svg" />
                </span>
              </div>
              <div>{item.description}</div>
            </Fragment>
          ))}
        </StyledTable>

        {!data?.transactions?.length && !isLoading ? (
          <Box
            sx={{
              color: "gray.main",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "24px 0",
            }}
          >
            No transactions
          </Box>
        ) : null}
      </Card>
    </PageWrapper>
  );
}
