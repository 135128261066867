import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic() {
  const methods = useForm();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.user.changePassword,
    method: "put",
  });

  const onSubmit = async (params) => {
    try {
      await mutate({ data: { params } });
      methods.reset();
      enqueueSnackbar("Changes saved", { variant: "success" });
    } catch (error) {}
  };

  const onCancel = () => {
    methods.reset({
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    });
  };

  return {
    methods,
    isLoading,
    onSubmit,
    onCancel,
  };
}
