import { FormProvider } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKTextField from "components/forms/rhk-text-field";
import { EMAIL_REGEX } from "lib/regex";
import useUILogic from "./logic";
import { StyledForm } from "./styles";

export default function ForgetPasswordForm({ setStep, setSavedData }) {
  const { methods, isLoading, onSubmit } = useUILogic({
    setStep,
    setSavedData,
  });

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Typography color="gray_iron.600" mb={3}>
          Enter your email account to reset password
        </Typography>

        <RHKTextField
          name="email"
          label="Email Address"
          type="email"
          rules={{
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: "* Email address format is not correct !",
            },
          }}
          disabled={isLoading}
        />

        <Box mt={10} />

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          size="large"
        >
          Send Code
        </LoadingButton>
      </StyledForm>
    </FormProvider>
  );
}
