import { Fragment } from "react";
import { Backdrop, Box, Divider, Drawer } from "@mui/material";
import Scrollbar from "components/scrollbar";
import SvgBox from "components/svg-box";
import NavLink from "./components/nav-link";
import NavLinkSubMenu from "./components/nav-link-sub-menu";
import useUILogic from "./logic";
import { StyledList, StyledLogo } from "./styles";

export default function NavDesktop() {
  const { sideMenu, wide, setWide, isDesktop } = useUILogic();

  const renderContent = (
    <Scrollbar>
      <div>
        <StyledLogo wide={isDesktop || wide}>
          <div>
            <SvgBox
              src={`/assets/logo${isDesktop || wide ? "" : "-simple"}.svg`}
              data-image
              component="svg"
            />
            {!isDesktop && (
              <div data-arrow onClick={() => setWide((prev) => !prev)}>
                <span />
              </div>
            )}
          </div>
          <Divider />
        </StyledLogo>

        <StyledList wide={isDesktop || wide}>
          <ul>
            {sideMenu?.map((item) => (
              <li key={item.title}>
                {!item.sub_menues ? (
                  <NavLink {...item} wide={isDesktop || wide} />
                ) : (
                  <NavLinkSubMenu {...item} wide={isDesktop || wide} />
                )}
              </li>
            ))}
          </ul>
        </StyledList>
      </div>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        minWidth: ({
          mixins: {
            navbar: { width, width_tablet },
          },
        }) => (isDesktop ? width : width_tablet),
      }}
    >
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={!isDesktop && wide}
        onClick={() => setWide(false)}
      />
      <Drawer
        open
        variant="persistent"
        sx={{
          width: ({
            mixins: {
              navbar: { width, width_tablet },
            },
          }) => (wide ? undefined : isDesktop ? width : width_tablet),
        }}
        PaperProps={{
          sx: {
            width: ({
              mixins: {
                navbar: { width, width_tablet },
              },
            }) => (isDesktop || wide ? width : width_tablet),
            bgcolor: "primary.700",
            border: "none",
            borderRadius: "0 16px 16px 0",
            transition: (theme) => theme.transitions.create(),
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
