import { useNavigate } from "react-router-dom";
import { logout, useGlobalStore } from "store/globalStore";
import useResponsive from "hooks/useResponsive";
import { Panel_Routes } from "routes/path";

export default function useUILogic() {
  const navigate = useNavigate();
  const user = useGlobalStore((state) => state.user);
  const isMobile = useResponsive("down", "sm");

  const handleLogout = () => {
    logout();
    navigate(Panel_Routes.auth.login);
  };

  return {
    user,
    handleLogout,
    isMobile,
  };
}
