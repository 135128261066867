import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { DEV_BACKEND_URL_API } from "routes/path";
import { useGlobalStore } from "store/globalStore";

export const axiosLib = axios.create({
  baseURL: DEV_BACKEND_URL_API,
  withCredentials: true,
});

axiosLib.interceptors.response.use(
  (res) => {
    if (res.data.data?.user) {
      useGlobalStore.setState(({ user }) => ({
        user: { ...user, ...res.data.data.user },
      }));
    }
    if (res.data.data?.side_menu) {
      useGlobalStore.setState(() => ({
        sideMenu: res.data.data.side_menu,
      }));
    }
    if (res.data.data?.notifications) {
      useGlobalStore.setState(({ notifications }) => ({
        notifications: [
          ...(notifications || []),
          ...res.data.data.notifications,
        ],
      }));
    }

    return res;
  },
  (error) => {
    if (!error.response) {
      enqueueSnackbar("Network Error", { variant: "error" });
    } else if (error.response.data?.message) {
      enqueueSnackbar(error.response.data.message, { variant: "error" });
    }

    return Promise.reject(error);
  }
);
