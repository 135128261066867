import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  Rating,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SvgBox from "components/svg-box";
import { capitalize } from "utils/helpers";
import useUILogic from "./logic";
import { StyledName } from "./styles";

export default function AddNewSkill() {
  const {
    dataGet,
    isLoadingGet,
    onOpen,
    profileData,
    //
    selectValue,
    setSelectValue,
    ratingValue,
    setRatingValue,
    //
    isLoadingAdd,
    onAddSkill,
  } = useUILogic();

  return (
    <StyledName>
      <Autocomplete
        options={
          dataGet?.list?.sort((a, b) => (a.group < b.group ? -1 : 1)) || []
        }
        groupBy={(option) => capitalize(option.group)}
        value={selectValue}
        onOpen={onOpen}
        onChange={(_, newValue) => setSelectValue(newValue)}
        filterOptions={(options) =>
          // remove selected options
          options.filter(
            ({ label }) =>
              !profileData?.skills.find(({ title }) => label === title)
          )
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disabled={!!selectValue}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoComplete="off"
              InputProps={{
                ...params.InputProps,
                startAdornment: isLoadingGet ? ( // FIXME wrong place in mobile
                  <InputAdornment position="start">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : null,
                endAdornment: selectValue ? (
                  <InputAdornment position="end">
                    <Rating
                      value={ratingValue}
                      onChange={(_, newValue) => setRatingValue(newValue)}
                    />
                    <LoadingButton
                      size="small"
                      variant="contained"
                      disabled={!ratingValue}
                      loading={isLoadingAdd}
                      onClick={() => onAddSkill()}
                    >
                      + Add New Skill
                    </LoadingButton>
                    <IconButton
                      onClick={() => {
                        setSelectValue(null);
                        setRatingValue(null);
                      }}
                    >
                      <SvgBox src="/assets/icons/common/ic_close.svg" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
              label="Skill Name"
              placeholder="Time Management"
            />
          );
        }}
      />
    </StyledName>
  );
}
