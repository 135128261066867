import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReactOtpInput from "react-otp-input";
import useUILogic from "./logic";
import { StyledBox } from "./styles";

export default function ForgetPasswordPin({
  setStep,
  savedData,
  setSavedData,
}) {
  const { otp, isLoading, onChange, onSend } = useUILogic({
    setStep,
    savedData,
    setSavedData,
  });

  return (
    <StyledBox isLoading={isLoading}>
      <Typography color="gray_icon.600" fontSize={18}>
        Confirmation code sent to
      </Typography>
      <Typography
        color="primary.800"
        fontSize={{ xs: 20, md: 24 }}
        fontWeight="medium"
        mt={3}
      >
        {savedData?.email}
      </Typography>
      <Typography color="gray_iron.800" fontSize={18} mt={3}>
        Insert confirmation code
      </Typography>

      <ReactOtpInput
        value={otp}
        onChange={onChange}
        numInputs={4}
        renderInput={(props) => <input {...props} placeholder="-" />}
      />

      <Box mt={6} />

      <LoadingButton
        variant="contained"
        loading={isLoading}
        size="large"
        onClick={() => onSend()}
      >
        Send
      </LoadingButton>
    </StyledBox>
  );
}
