import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";
import { useGlobalStore } from "store/globalStore";

export default function useUILogic() {
  const methods = useForm();
  const user = useGlobalStore((state) => state.user);

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.user.account,
    method: "put",
  });

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          avatar: params.avatar?.fileURL,
        },
      };

      await mutate({ data });
      enqueueSnackbar("Changes saved", { variant: "success" });
    } catch (error) {}
  };

  useEffect(() => {
    methods.reset({
      avatar: { fileURL: user?.avatar },
      full_name: user?.full_name,
      mobile_number: user?.mobile_number,
    });
  }, [user]);

  const onCancel = () => {
    methods.reset({
      avatar: { fileURL: user?.avatar },
      full_name: user?.full_name,
      mobile_number: user?.mobile_number,
    });
  };

  return {
    methods,
    isLoading,
    onSubmit,
    onCancel,
  };
}
