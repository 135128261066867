import SvgBox from "components/svg-box";
import useUILogic from "./logic";
import { StyledAlert } from "./styles";

export default function PanelAlert() {
  const { user } = useUILogic();

  return user?.status?.title !== "verified" ? (
    <StyledAlert>
      <SvgBox src="/assets/icons/header/ic_alert_octagon.svg" />
      <div>
        <strong>
          {user?.status?.title === "pending"
            ? "User Pending Verification :"
            : user?.status?.title === "rejected"
            ? "User Profile Rejected :"
            : null}
          &nbsp;&nbsp;
        </strong>
        <p>{user?.status?.reason}</p>
      </div>
    </StyledAlert>
  ) : null;
}
