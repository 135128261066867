import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { TextField } from "@mui/material";

export default function RHKTextField({ name, rules, ...other }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: "" });

  return (
    <TextField
      {...field}
      inputRef={ref}
      error={!!error}
      helperText={error ? error?.message || "* This field is required" : ""}
      {...other}
    />
  );
}

RHKTextField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
