import { alpha, createTheme } from "@mui/material/styles";

const { palette: MuiPalette } = createTheme();
const { augmentColor } = MuiPalette;

// ----------------------------------------------------------------------
// SETUP COLORS

const GRAY = {
  main: "#667085",
  25: "#FCFCFD",
  50: "#F9FAFB",
  100: "#F2F4F7",
  200: "#EAECF0",
  300: "#D0D5DD",
  400: "#98A2B3",
  500: "#667085",
  600: "#475467",
  700: "#344054",
  800: "#1D2939",
  900: "#101828",
};
const GRAY_IRON = {
  main: "#70707B",
  25: "#FCFCFC",
  50: "#FAFAFA",
  100: "#F4F4F5",
  200: "#E4E4E7",
  300: "#D1D1D6",
  400: "#A0A0AB",
  500: "#70707B",
  600: "#51525C",
  700: "#3F3F46",
  800: "#26272B",
  900: "#18181B",
};

const PRIMARY = {
  main: "#1FAE83",
  25: "#F4FEFB",
  50: "#EAFDF8",
  100: "#D4FAEF",
  200: "#A9F6DF",
  300: "#7FF1CF",
  400: "#54EDBF",
  500: "#29E8AF",
  600: "#1FAE83",
  700: "#157458",
  800: "#0A3A2C",
  900: "#041712",
};
const ERROR = {
  main: "#F04438",
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#F04438",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A",
};
const WARNING = {
  main: "#F79009",
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#B54708",
  800: "#93370D",
  900: "#7A2E0E",
};
const SUCCESS = {
  main: "#12B76A",
  25: "#F6FEF9",
  50: "#ECFDF3",
  100: "#D1FADF",
  200: "#A6F4C5",
  300: "#6CE9A6",
  400: "#32D583",
  500: "#12B76A",
  600: "#039855",
  700: "#027A48",
  800: "#05603A",
  900: "#054F31",
};

const palette = {
  primary: PRIMARY,
  error: ERROR,
  warning: WARNING,
  success: SUCCESS,
  divider: "#F4F4F5",
  text: {
    primary: GRAY[600],
    secondary: GRAY[800],
    disabled: "#98A2B3",
  },
  background: {
    default: "#EDEDED",
  },
  gray: GRAY,
  gray_iron: GRAY_IRON,
};

export default palette;
