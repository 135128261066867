import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import SvgBox from "components/svg-box";
import { ERROR_401_PAGE_TITLE } from "lib/page-titles";
import { StyledWrapper } from "./styles";

export default function Page401() {
  return (
    <>
      <Helmet>
        <title>{ERROR_401_PAGE_TITLE}</title>
      </Helmet>

      <StyledWrapper>
        <div>
          <div>
            <SvgBox
              src="/assets/icons/error/ic_unauthorized.svg"
              component="img"
            />
            <h1>
              <span>U</span>n-<span>A</span>uthorized <span>U</span>ser{" "}
              <span>!</span>
            </h1>
            <p>Some text about error reasons</p>
          </div>
          <div>
            Return to <Link to="/">User Profile</Link>
          </div>
        </div>

        <svg
          data-mountain
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1572 465"
        >
          <path
            d="M170.046-110.416,580.979,34.2,1266.974-87.653,1572-34.091V341.654c0,7.141-7.163,12.93-16,12.93H16c-8.837,0-16-5.789-16-12.93V-8.258Z"
            transform="translate(0 110.416)"
            fill="gray"
            opacity="0.2"
          />
        </svg>
        <svg
          data-mountain
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1572 287.708"
        >
          <path
            d="M326.826-7.914l335.707-102.5L1233.989,26.033,1572-63.191V169.292c0,4.418-7.163,8-16,8H16c-8.837,0-16-3.582-16-8v-216.5Z"
            transform="translate(0 110.416)"
            fill="gray"
            opacity="0.1"
          />
        </svg>
      </StyledWrapper>
    </>
  );
}
