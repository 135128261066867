import { styled } from "@mui/material/styles";

export const StyledCard = styled("div")(({ theme }) => ({
  background: "rgb(0,119,82)",
  background:
    "linear-gradient(180deg, rgba(210,210,210,.56) 0%, rgba(170,233,213,.98) 100%)",
  padding: "50px",
  borderRadius: "15px",
  marginBottom: "120px",
  position: "relative",
  backdropFilter: "blur(5px)",

  ">div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 24,
  },

  [theme.breakpoints.down("md")]: {
    padding: "20px",
    marginBottom: "60px",
    ">div": {
      ".svg-box": {
        width: "85px",
        height: "31px",
      },
      ".lazy-image": {
        width: "51px",
        height: "52px",
      },
    },
  },
}));

export const StyledRegister = styled("div")(({ theme }) => ({
  background: "rgb(242,242,242)",
  background:
    "linear-gradient(180deg, rgba(242,242,242,0.87) 0%, rgba(238,238,238,0.72) 100%)",
  padding: "50px 32px 65px",
  borderRadius: "10px",
  position: "relative",
  backdropFilter: "blur(2px)",

  ".MuiLoadingButton-root": {
    borderRadius: "5px",
    width: 262,
    height: 57,
    fontWeight: 700,
    fontFamily: "inherit",

    "&:not(:disabled)": {
      backgroundColor: "#268C6A !important",
      color: "white !important",
      "&:hover": {
        backgroundColor: "rgb(26, 98, 74) !important",
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    padding: "30px 14px",
  },
}));

export const StyledPar = styled("div")(({ theme }) => ({
  ">span": {
    position: "fixed",
    // width: "280px",
    "&:first-of-type": {
      right: 0,
      top: "50px",
    },
    "&:last-of-type": {
      left: 0,
      bottom: "-50px",
    },
  },

  [theme.breakpoints.down("lg")]: {
    ">span": {
      width: "220px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    ">span": {
      width: "180px",
      "&:last-of-type": {
        // display: "none",
        visibility: "hidden",
      },
    },
  },
}));

export const StyledLoading = styled("div")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 1399,
  background: "#fff",
  // backdropFilter: "blur(10px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
