import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // get list
  const {
    data: dataGet,
    isLoading: isLoadingGet,
    mutate: mutateGet,
  } = useAxios({
    url: Panel_API_Routes.resources.skills,
  });
  const onOpen = () => {
    if (!dataGet?.list?.length) mutateGet();
  };

  // values
  const [selectValue, setSelectValue] = useState(null);
  const [ratingValue, setRatingValue] = useState(null);

  // add
  const { isLoading: isLoadingAdd, mutate: mutateAdd } = useAxios({
    url: Panel_API_Routes.profile.skills.add,
    method: "post",
  });

  const onAddSkill = async () => {
    try {
      await mutateAdd({
        data: { params: { skill: selectValue?.value, rate: ratingValue } },
      });

      setSelectValue(null);
      setRatingValue(null);
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    dataGet,
    isLoadingGet,
    onOpen,
    profileData,
    //
    selectValue,
    setSelectValue,
    ratingValue,
    setRatingValue,
    //
    isLoadingAdd,
    onAddSkill,
  };
}
