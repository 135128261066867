import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, InputAdornment } from "@mui/material";
import { useFormContext } from "react-hook-form";
import SvgBox from "components/svg-box";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";
import RHKSelect from "../rhk-select";

export default function RHKSelectCountry({ query = "country", ...other }) {
  const { watch } = useFormContext();
  const selectValue = watch(other.name);

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState();

  const { data, isLoading, mutate } = useAxios({
    url: Panel_API_Routes.resources.location,
    onSuccess: (res) => setOptions(res.data?.data?.list),
  });

  // wait 800ms since user last type then call api(debounce)
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (inputValue) {
        mutate({ params: { query, search: inputValue } });
      }
    }, 800);
    return () => clearTimeout(timeOutId);
  }, [inputValue]);

  return (
    <RHKSelect
      {...other}
      options={options || []}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        if (newInputValue && event?.type === "change") {
          setInputValue(newInputValue);
        }
      }}
      onOpen={() => {
        if (!options?.length) mutate({ params: { query } });
      }}
      onClose={() => {
        // clear searched options
        if (inputValue) {
          setOptions([]);
          setInputValue("");
        }
      }}
      startAdornment={
        isLoading ? (
          <InputAdornment position="start">
            <CircularProgress color="inherit" size={20} />
          </InputAdornment>
        ) : selectValue && query === "country" ? (
          <InputAdornment position="start">
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${selectValue.iso2?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${selectValue.iso2?.toLowerCase()}.png 2x`}
              alt={selectValue.iso2}
              style={{ marginLeft: 8, marginRight: -8 }}
            />
          </InputAdornment>
        ) : null
      }
      renderOption={(props, option) => (
        <Box sx={{ ">img": { mr: 2, flexShrink: 0 } }} {...props}>
          {query === "country" && (
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.iso2?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.iso2?.toLowerCase()}.png 2x`}
              alt={option.iso2}
            />
          )}
          {option.label}
        </Box>
      )}
    />
  );
}

RHKSelect.propTypes = {
  query: PropTypes.oneOf(["state", "country"]),
};
