import { useState } from "react";

export default function useUILogic() {
  const [step, setStep] = useState("form");
  const [savedData, setSavedData] = useState();

  return {
    step,
    setStep,
    savedData,
    setSavedData,
  };
}
