import { Card, Divider, Typography } from "@mui/material";
import LanguageSection from "./components/language-section";
import SkillsSection from "./components/skills-section";

export default function ProfileInterpersonalSkillsPage() {
  return (
    <Card
      sx={{
        p: {
          xs: "24px 16px",
          md: "32px 32px 40px",
        },
      }}
    >
      <Typography color="gray.600">
        Some description here to help the student. or we can remove it completly
      </Typography>

      <Divider sx={{ mt: 3, mb: 4 }} />

      <LanguageSection />

      <Divider sx={{ mt: 4, mb: 4 }} />

      <SkillsSection />
    </Card>
  );
}
