export default function DatePicker(theme) {
  return {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          ".MuiMonthCalendar-root": {
            ".Mui-disabled": {
              color: theme.palette.gray[400],
            },
          },
        },
      },
    },
  };
}
