import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import useLocalStorage from "hooks/useLocalStorage";
import { STORAGE_KEY_USER } from "../../config";
import { StyledMain } from "./styles";

export default function AuthLayout() {
  let [searchParams] = useSearchParams();
  const [user] = useLocalStorage(STORAGE_KEY_USER);

  if (user) {
    return <Navigate to={searchParams.get("next") || "/"} />;
  }

  return (
    <StyledMain>
      <Outlet />
    </StyledMain>
  );
}
