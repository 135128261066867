import { styled } from "@mui/material/styles";

export const StyledHead = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ">div": {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 8,
  },
  button: {
    width: 36,
    height: 36,
    color: "#292D32",
    backgroundColor: theme.palette.primary[50],
    borderRadius: 8,
  },
}));
