import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const { error, data, isLoading } = useAxios({
    url: Panel_API_Routes.wallet.getInfo,
    loadOnStart: true,
  });

  return {
    error,
    data,
    isLoading,
  };
}
