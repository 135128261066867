import { Card, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageWrapper from "components/page-wrapper";
import SvgBox from "components/svg-box";
import { SETTINGS_PAGE_TITLE } from "lib/page-titles";
import GeneralInfo from "./components/general-info";
import ChangePassword from "./components/change-password";
import useUILogic from "./logic";

export default function SettingsPage() {
  const { currentTab, onChangeTab } = useUILogic();

  return (
    <PageWrapper pageTitle={SETTINGS_PAGE_TITLE} title="Settings">
      <Card
        sx={{
          p: {
            xs: "24px 16px",
            md: "32px",
          },
        }}
      >
        <TabContext value={currentTab}>
          <TabList onChange={onChangeTab}>
            <Tab
              iconPosition="start"
              label="General information"
              icon={
                <SvgBox src="/assets/icons/panel/settings/ic_general_info.svg" />
              }
              value="general_info"
            />
            <Tab
              iconPosition="start"
              label="Change password"
              icon={
                <SvgBox src="/assets/icons/panel/settings/ic_password.svg" />
              }
              value="change_password"
            />
          </TabList>

          <TabPanel value="general_info">
            <GeneralInfo />
          </TabPanel>
          <TabPanel value="change_password">
            <ChangePassword />
          </TabPanel>
        </TabContext>
      </Card>
    </PageWrapper>
  );
}
