import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useResponsive from "hooks/useResponsive";
import { useGlobalStore } from "store/globalStore";

export default function useUILogic() {
  const sideMenu = useGlobalStore((state) => state.sideMenu);
  const [wide, setWide] = useState(false);
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (wide) setWide(false);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    setWide(false);
  }, [isDesktop]);

  return {
    sideMenu,
    wide,
    setWide,
    isDesktop,
  };
}
