import SvgBox from "components/svg-box";

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiAutocomplete-popupIndicator": {
            top: 3,
            ".svg-box": {
              width: 20,
              height: 20,
            },
          },
          ".MuiAutocomplete-clearIndicator": {
            top: 2,
            ".svg-box": {
              width: 15,
              height: 15,
            },
          },

          ".MuiInputBase-root": {
            "input.MuiAutocomplete-input": {
              padding: "1.5px 4px 1.5px 6px",
            },
          },
        },
      },
      defaultProps: {
        popupIcon: <SvgBox src="/assets/icons/components/ic_arrow_down.svg" />,
        clearIcon: <SvgBox src="/assets/icons/common/ic_close.svg" />,
        openOnFocus: true,
      },
    },
  };
}
