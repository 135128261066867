import PropTypes from "prop-types";
import { Popover } from "@mui/material";
import SvgBox from "components/svg-box";
import { StyledContent } from "./styles";

export default function AlertPopover({ alertAnchorEl, handleCloseAlert }) {
  return (
    <Popover
      open={!!alertAnchorEl}
      anchorEl={alertAnchorEl}
      onClose={handleCloseAlert}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      marginThreshold={10}
      PaperProps={{
        sx: {
          mt: { xs: "8px", sm: "25px" },
          backgroundColor: (theme) => theme.palette.warning.main,
          borderRadius: "8px",
        },
      }}
    >
      <StyledContent>
        <div>
          <SvgBox src="/assets/icons/header/ic_warning.svg" component="svg" />

          <span>System is going for upgrading for 2 days</span>
        </div>

        <span onClick={() => handleCloseAlert()}>
          <SvgBox src="/assets/icons/common/ic_close.svg" />
        </span>
      </StyledContent>
    </Popover>
  );
}

AlertPopover.propTypes = {
  alertAnchorEl: PropTypes.object,
  handleCloseAlert: PropTypes.func,
};
