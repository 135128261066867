import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import SvgBox from "components/svg-box";
import { ERROR_PAGE_TITLE } from "lib/page-titles";
import { StyledContent, StyledWrapper } from "./styles";

export default function ErrorPage() {
  return (
    <>
      <Helmet>
        <title>{ERROR_PAGE_TITLE}</title>
      </Helmet>

      <StyledWrapper>
        <div>
          <span />
          <span />
        </div>

        <StyledContent>
          <div>
            <div data-cloud>
              <SvgBox src="/assets/icons/error/ic_cloud.svg" component="img" />
              <SvgBox
                src="/assets/icons/error/ic_exclamation.svg"
                component="img"
              />
            </div>

            <h1>
              <span>S</span>omething <span>W</span>ent <span>W</span>rong{" "}
              <span>!</span>
            </h1>
            <p>We apologize for the inconvenience, please try again</p>
          </div>

          <div>
            Return to <Link to="/">www.SelectYourVilla.com</Link>
          </div>
        </StyledContent>
      </StyledWrapper>
    </>
  );
}
