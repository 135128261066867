import { Helmet } from "react-helmet-async";
import { CAMPAIGN_REGISTER_PAGE_TITLE } from "lib/page-titles";
import useUILogic from "./logic";
import { FormProvider } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import RHKTextField2 from "components/forms/rhk-text-field-2";
import { LoadingButton } from "@mui/lab";
import { EMAIL_REGEX } from "lib/regex";
import { StyledCard, StyledLoading, StyledPar, StyledRegister } from "./styles";
import RHKCustomPatternFormat2 from "components/forms/rhk-custom-pattern-format-2";
import RHKSelect2 from "components/forms/rhk-select-2";
import SvgBox from "components/svg-box";
import Image from "components/image";
import RHKDatePicker2 from "components/forms/rhk-date-picker-2";
import Modal from "components/modal";
import BulletIcon from "@mui/icons-material/FiberManualRecord";

export default function RegisterCampaignPage() {
  const {
    methods,
    loadedImage,
    setLoadedImage,
    //
    campaignData,
    //
    openSuccess,
    toggleSuccess,
    isLoading,
    onSubmit,
  } = useUILogic();
  return (
    <>
      <Helmet>
        <title>{CAMPAIGN_REGISTER_PAGE_TITLE}</title>
      </Helmet>

      {/* {loadedImage < 3 && (
        <StyledLoading>
          <CircularProgress size={100} />
        </StyledLoading>
      )} */}

      <StyledPar>
        <Image
          src="/assets/images/par-1.png"
          placeholderSrc={null}
          onLoad={() => setLoadedImage((prev) => prev + 1)}
        />
        <Image
          src="/assets/images/par-2.png"
          placeholderSrc={null}
          onLoad={() => setLoadedImage((prev) => prev + 1)}
        />
      </StyledPar>

      <Box bgcolor="#E1E1E1">
        <Box
          sx={{
            direction: "rtl",
            width: { lg: "50%" },
            mx: { xs: "14px", sm: "100px", lg: "auto" },
            fontFamily: "Ubuntu Arabic",
          }}
        >
          <Typography
            variant="h5"
            color="#2A9975"
            fontWeight={700}
            py={{ xs: "35px", md: "100px" }}
            textAlign="center"
            fontFamily="inherit"
            fontSize={{ xs: "1.12rem", md: "1.5rem" }}
          >
            استمارة التقديم لبرنامج إنجاز
          </Typography>

          <StyledCard>
            <p>
              مرحبا بكم في الخطوة الاولى من برنامج إنجاز
              <br />
              إنجاز هو برنامج تدريبي مبني على المهارة، صمم وطور ليحدث قفزة في عالم التدريبات، هذه النسخة ستكون بالتعاون مع معهد اكسفورد حيث سيزودكم بجميع المهارات اللازمة والضرورية للدخول الى سوق العمل.
              <br />
              <br />
              <span>
                المسارات التطويرية : المحاسبة ، الادارة ، التسويق ، الموارد
                البشرية و تكنولوجيا المعلومات
              </span>
            </p>

            <List dense>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <BulletIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary="موعد الانطلاق: 2024-07-20"
                  sx={{ textAlign: "right" }}
                />
              </ListItem>

              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <BulletIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  sx={{ textAlign: "right" }}
                  primary="مدة البرنامج: شهرين"
                />
              </ListItem>

              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <BulletIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  sx={{ textAlign: "right" }}
                  primary="جدول البرنامج:"
                />
              </ListItem>

              <ListItem>
                <ListItemText disableTypography sx={{paddingRight:'20px',textAlign:"right"}} primary="البرنامج الصباحي: 2:00pm – 9:00am"/>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography sx={{paddingRight:'20px',textAlign:"right"}} primary="البرنامج الصباحي: 2:00pm – 9:00am"/>
              </ListItem>

              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <BulletIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  sx={{ textAlign: "right" }}
                  primary="تكلفة البرنامج: 1,250,000 دينار عراقي."
                />
              </ListItem>


              <ListItem>
                <ListItemIcon sx={{ minWidth: "20px" }}>
                  <BulletIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  sx={{ textAlign: "right" }}
                  primary="العدد محدود 25 طالب فقط"
                />
              </ListItem>
            </List>

            <p>
              <br />
              <span>
              ستمنح شهادة معتمدة عالميا من معهد أوكسفورد اضافة الى شهادة من شركة سراج المعرفة  ورسالة توصية في نهاية البرنامج 
              </span>
              <br />
              <br/>
              <span>مسيرة الأنجاز تبدأ بخطوة ... إتخذ خطوتك الآن</span>
            </p>

            <div>
              <SvgBox
                src="/assets/logo-2.svg"
                component="img"
                sx={{ width: 124, height: 54 }}
              />
              <Image
                src="/assets/images/oxford-institute.png"
                placeholderSrc={null}
                onLoad={() => setLoadedImage((prev) => prev + 1)}
              />
            </div>
          </StyledCard>

          <StyledRegister>
            <Typography
              variant="h5"
              color="#2A9975"
              fontWeight={700}
              p="0 0 40px"
              textAlign="center"
              fontFamily="inherit"
              fontSize={{ xs: "1.12rem", md: "1.5rem" }}
            >
              المعلومات الأساسية
            </Typography>

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <Grid container rowSpacing={{ xs: 2, md: 4 }} columnSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <RHKTextField2
                      name="full_name"
                      label="الاسم الكامل"
                      rules={{ required: true }}
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKDatePicker2
                      name="birth_date"
                      label="تاريخ الميلاد"
                      rules={{ required: true }}
                      disabled={isLoading}
                      views={["year", "month", "day"]}
                      openTo="year"
                      maxDate={new Date()}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKTextField2
                      name="email"
                      label="البريد الإلكتروني"
                      type="email"
                      rules={{
                        required: true,
                        pattern: {
                          value: EMAIL_REGEX,
                          message: "البريد الإلكتروني غير صحيح",
                        },
                      }}
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKCustomPatternFormat2
                      name="mobile"
                      format="#### ### ####"
                      label="رقم الهاتف"
                      disabled={isLoading}
                      rules={{
                        required: true,
                        pattern: {
                          value: /^07[0-9]{9}/,
                          message: "رقم الهاتف غير صحيح",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHKTextField2
                      name="address"
                      label="العنوان"
                      rules={{ required: true }}
                      disabled={isLoading}
                      multiline
                      minRows={4}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKSelect2
                      name="eng_lang_level"
                      label="مستوى اللغة الانكليزية"
                      rules={{ required: true }}
                      disabled={isLoading}
                      options={campaignData?.experience_level || []}
                      placeholder="رجاءً حدد"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKSelect2
                      name="gender"
                      label="الجنس"
                      rules={{ required: true }}
                      disabled={isLoading}
                      options={campaignData?.gender || []}
                      placeholder="رجاءً حدد"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKSelect2
                      name="specialty"
                      label="التخصص المطلوب"
                      rules={{ required: true }}
                      disabled={isLoading}
                      options={campaignData?.expertise || []}
                      placeholder="رجاءً حدد"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <RHKTextField2
                      name="uni_inst"
                      label="التحصيل الدراسي"
                      rules={{ required: true }}
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHKTextField2
                      name="q1"
                      label="خبرات سابقة"
                      rules={{ required: true }}
                      disabled={isLoading}
                      multiline
                      minRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHKTextField2
                      name="q2"
                      label="ما هي أسباب اهتمامك بالمشاركة في هذا البرنامج؟"
                      rules={{ required: true }}
                      disabled={isLoading}
                      multiline
                      minRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHKTextField2
                      name="q3"
                      label="هل لديك أي أهداف خاصة تأمل تحقيقها من خلال هذا البرنامج؟"
                      rules={{ required: true }}
                      disabled={isLoading}
                      multiline
                      minRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHKTextField2
                      name="q4"
                      label="كيف تعتقد أنك ستساهم في الفعاليات والأنشطة المتعلقة بالبرنامج؟"
                      rules={{ required: true }}
                      disabled={isLoading}
                      multiline
                      minRows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHKSelect2
                      name="q5"
                      label="هل انت واثق من انك قادر على حضور جميع الانشطة و المحاضرات لمدة شهرين ؟"
                      rules={{ required: true }}
                      disabled={isLoading}
                      options={campaignData?.binary || []}
                      placeholder="رجاءً حدد"
                      sx={{ width: { md: "50%" } }}
                    />
                  </Grid>

                  {/* <Grid item>
                    <Box sx={{ display: "flex" }}>
                      <span>
                        <SvgBox
                          src="/assets/icons/common/ic_info.svg"
                          sx={{
                            color: "#24906D",
                            ml: "4px",
                            width: 32,
                            height: 32,
                          }}
                        />
                      </span>
                      <p>
                        الملاحظة الاخيرة : مفتوح التسجيل حاليا على قسم المحاسبة
                        فقط ولكن في حال تواجد عدد كافي لاي من الاقسام الاربعة
                        الاخرى سوف يتم فتحها
                      </p>
                    </Box>
                  </Grid> */}

                  <Grid item xs={12} mt={3}>
                    <Box display="flex" justifyContent="center">
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                      >
                        أرسل النموذج
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </StyledRegister>

          <Typography p="80px 0 28px" textAlign="center" letterSpacing=".18em">
            By Siraj Al-Marafa Company
          </Typography>
        </Box>
      </Box>

      <Modal
        open={openSuccess}
        handleClose={() => window.location.replace("https://enjaz.training")}
      >
        <Box
          sx={{
            fontFamily: "Ubuntu Arabic",
            direction: "rtl",
            p: "40px",
            fontSize: "18px",
          }}
        >
          لقد تمت الخطوة الأولى في مسيرة الانجاز, سيتم التواصل معكم قريباً
          وتحديد موعد المقابلة , شكراً لكم
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "24px",
            fontFamily: "Ubuntu Arabic",
          }}
        >
          <Button
            onClick={() => window.location.replace("https://enjaz.training")}
            variant="contained"
            sx={{ px: "28px" }}
          >
            نعم
          </Button>
        </Box>
      </Modal>
    </>
  );
}
