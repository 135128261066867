import { FormProvider } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Modal from "components/modal";
import SvgBox from "components/svg-box";
import RHKTextField from "components/forms/rhk-text-field";
import RHKDatePicker from "components/forms/rhk-date-picker";
import RHKSwitch from "components/forms/rhk-switch";
import useUILogic from "./logic";

export default function WorkExperienceFormModal({
  open,
  toggle,
  methods,
  formMode,
}) {
  const { isLoading, onSubmit } = useUILogic({ toggle, formMode });

  return (
    <Modal
      open={open}
      handleClose={() => toggle()}
      maxWidth="sm"
      title={
        <Box
          sx={{
            p: "12px",
            border: "1px solid",
            borderColor: "gray.200",
            borderRadius: "10px",
            width: 48,
            height: 48,
          }}
        >
          <SvgBox src="/assets/icons/panel/profile/ic_flag.svg" />
        </Box>
      }
    >
      <Typography color="gray_iron.800" fontWeight="medium" fontSize={18}>
        {formMode === "add" ? "Add" : "Edit"} work experience
      </Typography>

      <Typography color="gray.600" mt={1} mb={3}>
        Share where you’ve educated on your profile.
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Grid container rowSpacing={{ xs: 2, md: 2.5 }} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <RHKTextField
                name="job_title"
                label="Job title"
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHKTextField
                name="company_name"
                label="Company name"
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHKDatePicker
                name="start_date"
                label="Start date"
                format="MMM, yyyy"
                views={["year", "month"]}
                maxDate={
                  methods.watch("end_date")
                    ? methods.watch("end_date")
                    : new Date()
                }
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHKDatePicker
                name="end_date"
                label="End date"
                format="MMM, yyyy"
                views={["year", "month"]}
                minDate={methods.watch("start_date")}
                maxDate={new Date()}
                rules={{ required: !methods.watch("currently_working") }}
                disabled={isLoading || methods.watch("currently_working")}
              />
            </Grid>
            <Grid item xs={12}>
              <RHKTextField
                name="responsibility"
                label="Describe role"
                multiline
                minRows={3.5}
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <RHKSwitch
                name="currently_working"
                label="Currently working"
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "100%" }}
              >
                {formMode === "add" ? "Add" : "Update"}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Modal>
  );
}
