import { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // group skills
  const groupedSkills = useMemo(() => {
    if (profileData?.skills) {
      const result = profileData.skills.reduce(function (r, a) {
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
      }, Object.create(null));

      return Object.keys(result).map((group_anme) => {
        return {
          group_anme,
          options: result[group_anme],
        };
      });
    }
  }, [profileData]);

  // delete
  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useAxios({
    url: Panel_API_Routes.profile.skills.delete,
    method: "delete",
  });

  const handleDelete = async (id) => {
    try {
      await mutateDelete({ data: { params: { id } } });
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    groupedSkills,
    handleDelete,
    isLoadingDelete,
  };
}
