import SvgBox from "components/svg-box";

export default function Rating(theme) {
  return {
    MuiRating: {
      styleOverrides: {
        root: {
          ".MuiRating-icon": {
            padding: "0 2px",
            color: theme.palette.primary.main,
          },
        },
      },
      defaultProps: {
        icon: <SvgBox src="/assets/icons/components/ic_star_fill.svg" />,
        emptyIcon: <SvgBox src="/assets/icons/components/ic_star.svg" />,
      },
    },
  };
}
