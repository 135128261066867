import { styled } from "@mui/material/styles";

export const StyledWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: "#ffffff",
  borderRadius: 16,
  position: "relative",

  display: "flex",
  justifyContent: "center",
  padding: "146px 0 64px 0",

  ">div": {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    ">div:first-of-type": {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ">.svg-box": {
        width: 224,
        height: 224,
      },
      h1: {
        letterSpacing: "4.67px",
        color: theme.palette.gray.main,
        lineHeight: "38px",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 32,
        marginTop: 12,
        span: {
          fontWeight: theme.typography.fontWeightMedium,
        },
        [theme.breakpoints.down("sm")]: {
          letterSpacing: "2.63px",
          lineHeight: "22px",
          fontSize: 18,
        },
      },
      p: {
        letterSpacing: "2.34px",
        color: theme.palette.gray.main,
        lineHeight: "19px",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 16,
        marginTop: 12,
        [theme.breakpoints.down("sm")]: {
          letterSpacing: "2.04px",
          lineHeight: "17px",
          fontSize: 14,
        },
      },
    },

    ">div:last-of-type": {
      letterSpacing: "2.63px",
      lineHeight: "22px",
      fontSize: 18,
      position: "relative",
      zIndex: 1,
      a: {
        fontWeight: theme.typography.fontWeightMedium,
        color: "inherit",
        textDecoration: "underline",
      },
      [theme.breakpoints.down("sm")]: {
        letterSpacing: "2.04px",
        lineHeight: "17px",
        fontSize: 14,
      },
    },
  },

  ">svg[data-mountain]": {
    width: "100%",
    display: "block",
    position: "absolute",
    bottom: 0,
  },
}));
