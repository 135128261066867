import { styled, alpha } from "@mui/material/styles";

export const StyledCountdown = styled("div")(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
  width: 46,
  height: 46,

  ">span": {
    color: "inherit",
    "&[data-full-circle]": {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      color: alpha(theme.palette.gray.main, 0.1),
    },
  },

  ">div": {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 15,
  },
}));
