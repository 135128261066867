import { styled, alpha } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const StyledContent = styled("div")(({ theme }) => ({
  minWidth: 300,
  maxWidth: 350,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,

  "&>div , >a": {
    padding: "18px 24px",
    display: "flex",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "calc(100% - 24px)",
      height: 1,
      left: 24,
      bottom: 0,
      backgroundColor: "#EDEDED",
    },

    ">.svg-box": {
      position: "relative",
      top: -2,
    },
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "unset",
  },
}));

export const StyledProfile = styled("div")(({ theme }) => ({
  padding: "12px 24px !important",
  "&:before": { width: "100% !important", left: "0 !important" },
  ".wrapper": {
    width: 20,
    height: 20,
    position: "relative",
    top: 2,
    img: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 20,
    },
  },
  ">div": {
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
    ">span:first-of-type": {
      letterSpacing: "1.54px",
      color: "#1F003E",
      textTransform: "uppercase",
    },
    ">span:last-of-type": {
      fontSize: 14,
    },
  },
}));

export const StyledEmail = styled("div")(({ theme }) => ({
  marginLeft: 8,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  ">:last-of-type": {
    fontSize: 14,
    color: theme.palette.gray_iron[600],
    overflowWrap: "break-word",
  },
}));

export const StyledAccountSettings = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  ">div": {
    marginLeft: 8,
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
}));

export const StyledFAQ = styled("div")(({ theme }) => ({
  cursor: "pointer",
  ">div": {
    marginLeft: 8,
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
}));

export const StyledLogout = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  cursor: "pointer",
  ">div": {
    marginLeft: 8,
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.main, 0.05),
  },
}));
