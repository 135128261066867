import { styled } from "@mui/material/styles";

export const StyledAuth = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#ffffff",
  [theme.breakpoints.down("md")]: {
    minHeight: "100%",
  },
}));

export const StyledFormWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  order: 1,
  ">div": {
    padding: "0 24px",
    maxWidth: 550,
    margin: "0 auto",

    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: "0 18px 32px",
    },

    ">div:first-of-type": {
      marginLeft: -24,
      ".svg-box": {
        width: 191,
        height: 75,
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        margin: "70px 0 90px",
        justifyContent: "center",
        ".svg-box": {
          width: 95,
          height: 37,
          "path[fill=black]": {
            fill: "#DADADA",
          },
        },
      },
    },
  },
}));

export const StyledImage = styled("div")(({ theme }) => ({
  flex: 1,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  img: {
    minHeight: "100vh",
    maxHeight: "100vh",
    width: "50vw",
  },

  ">div:first-of-type": {
    position: "absolute",
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(0, rgba(0, 0, 0, 0.69) 0%, rgba(0, 0, 0, 0) 100%)",
    div: {
      position: "absolute",
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(0, rgba(0, 0, 0, 0.69) 1.81%, rgba(0, 0, 0, 0) 56.26%)",
      backdropFilter: "blur(5px)",
    },
  },

  ">div:last-of-type": {
    position: "absolute",
    bottom: 125,
    padding: "0 8px",
    ".svg-box": {
      color: "#848484",
    },
    p: {
      marginTop: 64,
      color: "#ffffff",
      position: "relative",
      fontSize: 18,
      "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: -32,
        width: "75%",
        height: 1,
        backgroundColor: "#ffffff",
      },
      span: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      strong: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 32,
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
