import {
  NAVBAR_WIDTH,
  NAVBAR_WIDTH_TABLET,
  NAVBAR_HEIGHT_MOBIlE,
  TOOLBAR_HEIGHT,
  TOOLBAR_TOP,
  TOOLBAR_HEIGHT_MOBILE,
} from "../config";

const mixins = {
  toolbar: {
    height: TOOLBAR_HEIGHT,
    top: TOOLBAR_TOP,
    height_mobile: TOOLBAR_HEIGHT_MOBILE,
  },
  navbar: {
    width: NAVBAR_WIDTH,
    width_tablet: NAVBAR_WIDTH_TABLET,
    height_mobile: NAVBAR_HEIGHT_MOBIlE,
  },
};

export default mixins;
