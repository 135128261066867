import { useOutletContext } from "react-router-dom";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // get list
  const {
    data: dataGet,
    isLoading: isLoadingGet,
    mutate: mutateGet,
  } = useAxios({
    url: Panel_API_Routes.resources.languages,
  });
  const onOpen = () => {
    if (!dataGet?.list?.length) mutateGet();
  };

  // add and delete
  const { isLoading: isLoadingAdd, mutate: mutateAdd } = useAxios({
    url: Panel_API_Routes.profile.languages.add,
    method: "post",
  });

  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useAxios({
    url: Panel_API_Routes.profile.languages.delete,
    method: "delete",
  });

  const onChange = async (event, list, reason, detail) => {
    if (reason === "selectOption") {
      try {
        await mutateAdd({
          data: { params: { language: detail.option?.value } },
        });

        refetchProfile({ callStartup: true });
      } catch (error) {}
    }
  };

  const handleDelete = async (id) => {
    try {
      await mutateDelete({
        data: { params: { id } },
      });

      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    languages: profileData?.languages,
    //
    dataGet,
    isLoadingGet,
    onOpen,
    //
    isLoadingChange: isLoadingAdd || isLoadingDelete,
    onChange,
    handleDelete,
  };
}
