import SvgBox from "components/svg-box";
import BlinkingBadge from "components/blinking-badge";
import { StyledNavLink } from "./styles";

export default function NavLink({
  title,
  path,
  icon,
  blinking_badge,
  enabled,
  order,
  wide,
  ...other
}) {
  return (
    <StyledNavLink
      to={path}
      data-disabled={enabled === false}
      wide={wide}
      {...other}
    >
      <SvgBox src={`/assets/icons/navbar/${icon}.svg`} />
      <SvgBox src={`/assets/icons/navbar/${icon}_active.svg`} component="img" />
      <span data-title>{title}</span>
      {blinking_badge && (
        <BlinkingBadge
          size={16}
          sx={{
            position: "absolute",
            right: wide ? 24 : 0,
          }}
        />
      )}
    </StyledNavLink>
  );
}
