import SvgBox from "components/svg-box";
import { StyledAddNew } from "./styles";

export default function AddNew({ onClick }) {
  return (
    <StyledAddNew onClick={onClick}>
      <SvgBox src="/assets/icons/panel/profile/ic_plus.svg" />
      Add new
    </StyledAddNew>
  );
}
