import { InputLabel, TextField } from "@mui/material";
import { en2pr, pr2en } from "lib/helpers";
import { useController, useFormContext } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { StyledWrapper } from "./styles";

export default function RHKCustomPatternFormat2({
  name,
  rules,
  label,
  ...rest
}) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: "" });

  return (
    <StyledWrapper>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <PatternFormat
        id={field.name}
        customInput={TextField}
        inputRef={ref}
        value={field.value}
        onValueChange={(e) => {
          field.onChange(e.value);
        }}
        error={!!error}
        helperText={error ? error?.message || "هذا الحقل مطلوب" : ""}
        {...rest}
      />
    </StyledWrapper>
  );
}
