import { styled } from "@mui/material/styles";

export const StyledContent = styled("div")(({ theme }) => ({
  padding: "12px 5px 12px 12px",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 14,

  ">div": {
    display: "flex",
    alignItems: "center",
    marginRight: 4,
    ".svg-box": {
      marginRight: 8,
      width: 22,
      height: 20,
      "path:first-of-type": {
        fill: "#ffffff",
      },
      "path:last-of-type": {
        stroke: theme.palette.warning.main,
      },
      circle: {
        fill: theme.palette.warning.main,
      },
    },
  },

  ">span": {
    cursor: "pointer",
    padding: 7,
    display: "inline-flex",
    ">.svg-box": {
      width: 10,
      height: 10,
    },
  },
}));
