import { Typography } from "@mui/material";
import ReactOtpInput from "react-otp-input";
import SvgBox from "components/svg-box";
import Spin from "components/spin";
import CircularCountdown from "../circular-countdown";
import useUILogic from "./logic";
import { StyledBox } from "./styles";

export default function OtpInput({ status, setStatus }) {
  const { otp, isLoading, onChange, countdown } = useUILogic({
    status,
    setStatus,
  });

  return (
    <StyledBox status={status}>
      <Typography color="gray_iron.400" fontSize={12}>
        Insert confirmation SMS code
      </Typography>
      <div>
        <Spin isLoading={isLoading}>
          <ReactOtpInput
            value={otp}
            onChange={onChange}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
          />
        </Spin>
        <CircularCountdown
          countdown={countdown}
          total={120}
          content={
            status === "successful" ? (
              <SvgBox src="/assets/icons/components/ic_check.svg" />
            ) : null
          }
        />
      </div>
    </StyledBox>
  );
}
