import { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import SvgBox from "components/svg-box";
import { StyledDatePicker, StyledWrapper } from "./styles";
import { InputLabel } from "@mui/material";

export default function RHKDatePicker2({ name, rules, label, ...other }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  const [open, setOpen] = useState(false);

  return (
    <StyledWrapper>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <StyledDatePicker
        {...field}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slots={{
          openPickerButton: () => (
            <>
              {field.value && (
                <SvgBox
                  src="/assets/icons/common/ic_close.svg"
                  sx={{ width: 22, height: 22, ml: "4px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    field.onChange(null);
                  }}
                />
              )}
              <SvgBox
                src="/assets/icons/components/ic_calendar-2.svg"
                sx={{ width: 22, height: 22 }}
              />
            </>
          ),
        }}
        slotProps={{
          textField: {
            inputRef: ref,
            placeholder: "",
            error: !!error,
            helperText: error ? error?.message || "هذا الحقل مطلوب" : "",
            readOnly: true,
            onClick: () => !other.disabled && setOpen(!open),
          },
        }}
        {...other}
      />
    </StyledWrapper>
  );
}

RHKDatePicker2.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
