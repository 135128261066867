import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Spin from "components/spin";

export default function PageWrapper({
  pageTitle,
  title,
  isLoading,
  LoadingComponent,
  error,
  children,
}) {
  if (error) {
    if (error.response?.status === 401) return <Navigate to="/401" />;
    else return <Navigate to="/500" />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography
          color="primary.900"
          fontSize={24}
          fontWeight="medium"
          mb={3}
        >
          {title}
        </Typography>

        {isLoading && LoadingComponent ? (
          <LoadingComponent />
        ) : (
          <Spin
            sx={{ height: "100%", borderRadius: "16px" }}
            isLoading={!!isLoading}
          >
            {children}
          </Spin>
        )}
      </Box>
    </>
  );
}

PageWrapper.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.node,
};
