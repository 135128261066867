import { styled } from "@mui/material/styles";

export const StyledBox = styled("div")(({ theme, isLoading }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  display: "flex",
  flexDirection: "column",

  button: {
    marginTop: "auto",
  },

  ">div:first-of-type": {
    height: 56,
    marginTop: 24,
    justifyContent: "center",
    pointerEvents: !isLoading ? "auto" : "none",
    opacity: isLoading ? 0.5 : 1,
    input: {
      color: "inherit",
      border: `1px solid ${theme.palette.gray_iron[300]}`,
      borderRadius: 16,
      padding: "18px 0",
      fontSize: 16,
      width: "56px !important",
      outline: "none",
      "&:not(:last-of-type)": {
        marginRight: 24,
      },
    },
  },
}));
