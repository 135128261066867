import { useForm } from "react-hook-form";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic({ setStep, savedData }) {
  const methods = useForm();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.resetPassword,
    method: "post",
  });

  const onSubmit = async (params) => {
    try {
      const finalParams = {
        password: params.new_password,
        token: savedData?.passwordToken,
      };

      await mutate({ data: { params: finalParams } });
      setStep("success");
    } catch (error) {}
  };

  return {
    methods,
    isLoading,
    onSubmit,
  };
}
