import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import SvgBox from "components/svg-box";
import { fDate } from "utils/formatTime";
import useUILogic from "./logic";
import { StyledInfo } from "./styles";

export default function InfoBox({ item, type, onEditClick, onDeleteClick }) {
  const { anchorEl, handleOpenMenu, handleCloseMenu } = useUILogic();

  return (
    <>
      <StyledInfo>
        <div>
          {type === "work" ? (
            <>
              <div>
                {item?.job_title} - {item?.company_name}
              </div>
              <span>
                {fDate(item?.start_date, "yyyy-MMM")} ●{" "}
                {fDate(item?.end_date, "yyyy-MMM")}
              </span>
            </>
          ) : type === "education" ? (
            <>
              <div>
                {item?.degree} - {item?.field_of_study}
              </div>
              <span>
                {item?.institution_name} - {item?.status}
              </span>
            </>
          ) : type === "certificate" ? (
            <>
              <div>
                {item?.title} - {item?.issuer}
              </div>
              <span>
                {item?.url && (
                  <Box
                    component="a"
                    href={item?.url}
                    target="_blank"
                    color="info.main"
                  >
                    view file
                  </Box>
                )}
              </span>
            </>
          ) : null}
        </div>
        <IconButton onClick={handleOpenMenu}>
          <SvgBox src="/assets/icons/panel/profile/ic_dots_vertical.svg" />
        </IconButton>
      </StyledInfo>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => {
            onEditClick();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <SvgBox src="/assets/icons/common/ic_edit.svg" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteClick();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <SvgBox src="/assets/icons/common/ic_delete.svg" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
