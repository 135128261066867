import { FormProvider } from "react-hook-form";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKCustomDropzone from "components/forms/rhk-custom-dropzone";
import RHKTextField from "components/forms/rhk-text-field";
import RHKPhone from "components/forms/rhk-phone";
import SvgBox from "components/svg-box";
import Image from "components/image";
import useUILogic from "./logic";
import { StyledUpload } from "./styles";

export default function GeneralInfo() {
  const { methods, isLoading, onSubmit, onCancel } = useUILogic();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Typography color="gray.600" my={{ xs: 3, md: 5 }}>
          Update your photo and personal details here.
        </Typography>

        <Grid
          container
          columnSpacing={{ xs: 3, lg: 6 }}
          rowSpacing={3}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Grid item>
            <Typography color="gray_iron.800" fontSize={18} fontWeight="medium">
              Your photo
            </Typography>
            <Typography color="gray_iron.500" fontSize={14} mt={1}>
              This will be displayed on your profile.
            </Typography>
          </Grid>
          <Grid item>
            <Image
              sx={{ width: "64px", height: "64px", borderRadius: "100%" }}
              src={methods.watch("avatar")?.fileURL}
              type="avatar"
            />
          </Grid>
          <Grid item ml={{ xs: 0, md: "auto" }} borderRadius="16px">
            <RHKCustomDropzone name="avatar" accept={{ "image/*": [] }}>
              {({ error }) => (
                <StyledUpload>
                  <span>
                    <span>
                      <SvgBox src="/assets/icons/common/ic_upload_cloud.svg" />
                    </span>
                  </span>
                  <div>
                    <span>Click to upload</span> or drag and drop
                    <br /> SVG, PNG, JPG or GIF (max. 800x400px)
                  </div>
                  {error && (
                    <Typography color="error" fontSize={12} mt={1}>
                      Something went wrong
                    </Typography>
                  )}
                </StyledUpload>
              )}
            </RHKCustomDropzone>
          </Grid>
        </Grid>

        <Grid container rowSpacing={{ xs: 3, md: 5 }}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography color="gray_iron.600">Name</Typography>
          </Grid>

          <Grid item xs={12} md={4.5}>
            <RHKTextField
              name="full_name"
              label="Full Name"
              placeholder="Type Here"
              rules={{ required: true }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12} md={4.5}>
            <Divider sx={{ display: { md: "none" } }} />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography color="gray_iron.600">Phone Number</Typography>
          </Grid>

          <Grid item xs={12} md={9}>
            <RHKPhone
              name="mobile_number"
              label="Wahtsapp Number"
              placeholder="+xx xxx xxx xxxx"
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item ml="auto">
            <Button
              variant="outlined"
              color="gray"
              sx={{
                minWidth: { md: 185 },
                color: "gray_iron.800",
                mr: { xs: 2, md: 4 },
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              sx={{ minWidth: { md: 185 } }}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
