import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";
import { StyledSpin } from "./styles";

export default function Spin({ children, isLoading, progressProps, ...other }) {
  return (
    <StyledSpin {...other}>
      <Backdrop open={isLoading}>
        {isLoading && <CircularProgress color="inherit" {...progressProps} />}
      </Backdrop>
      {children}
    </StyledSpin>
  );
}

Spin.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  progressProps: PropTypes.object,
};
