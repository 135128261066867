import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import SvgBox from "components/svg-box";
import { ERROR_404_PAGE_TITLE } from "lib/page-titles";
import { StyledContent, StyledWrapper } from "./styles";

export default function Page404() {
  return (
    <>
      <Helmet>
        <title>{ERROR_404_PAGE_TITLE}</title>
      </Helmet>

      <StyledWrapper>
        <div>
          <span />
          <span />
        </div>

        <StyledContent>
          <div>
            <div data-cloud>
              <SvgBox src="/assets/icons/error/ic_cloud.svg" component="img" />
              <div>
                <SvgBox src="/assets/icons/error/ic_4.svg" component="img" />
                <SvgBox src="/assets/logo.svg" />
                <SvgBox src="/assets/icons/error/ic_4.svg" component="img" />
              </div>
            </div>

            <h1>
              <span>P</span>age <span>N</span>ot <span>F</span>ound{" "}
              <span>{":("}</span>
            </h1>
          </div>

          <div>
            Return to <Link to="/">www.SelectYourVilla.com</Link>
          </div>
        </StyledContent>
      </StyledWrapper>
    </>
  );
}
