import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@mui/material";
import SVG from "react-inlinesvg";

const SvgBox = forwardRef(
  ({ src, component = "span", sx, className, ...other }, ref) => (
    <Box
      component={component === "svg" ? SVG : component}
      className={clsx("svg-box", className)}
      {...(component === "svg" ? { innerRef: ref } : { ref })}
      {...((component === "img" || component === "svg") && {
        src: `${process.env.PUBLIC_URL}${src}`,
      })}
      sx={{
        width: 24,
        height: 24,
        display: "inline-block",
        ...(component !== "img" &&
          component !== "svg" && {
            bgcolor: "currentColor",
            mask: `url(${process.env.PUBLIC_URL}${src}) no-repeat center / contain`,
            WebkitMask: `url(${process.env.PUBLIC_URL}${src}) no-repeat center / contain`,
          }),
        ...sx,
      }}
      {...other}
    />
  )
);

SvgBox.propTypes = {
  src: PropTypes.string.isRequired,
  component: PropTypes.string,
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default SvgBox;
