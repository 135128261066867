import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import SvgBox from "components/svg-box";
import { Panel_Routes } from "routes/path";
import { StyledBox } from "./styles";

export default function ForgetPasswordSuccess() {
  return (
    <StyledBox>
      <SvgBox src="/assets/icons/auth/ic_success.svg" />
      <Typography color="gray_iron.600" fontSize={18} mt={5}>
        Password Changed Successfully
      </Typography>

      <Box mt={3} />

      <Link to={Panel_Routes.auth.login}>
        <Button variant="contained" size="large">
          OK
        </Button>
      </Link>
    </StyledBox>
  );
}
