export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          fontWeight: theme.typography.fontWeightMedium,
          textTransform: "none",
          boxShadow: "0px 1px 2px 0px #1018280D",
          fontFamily: "inherit",
          "&:hover": {
            boxShadow: "0px 1px 2px 0px #1018280D",
          },

          "&.MuiButton-sizeLarge": {
            padding: "16px 22px",
            fontSize: 18,
          },
          "&.MuiButton-sizeMedium": {
            padding: "10px 18px",
            fontSize: 16,
          },

          "&.MuiButton-containedPrimary:not(.Mui-disabled)": {
            backgroundColor: theme.palette.primary[500],
            color: theme.palette.primary[700],
            "&:hover": {
              backgroundColor: theme.palette.primary[600],
            },
          },
        },
      },
    },
  };
}
