import { styled } from "@mui/material/styles";

export const StyledUpload = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.gray[200]}`,
  borderRadius: 16,
  padding: "12px 40px",
  textAlign: "center",
  cursor: "pointer",

  ">span": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    width: 40,
    height: 40,
    backgroundColor: theme.palette.gray[50],
    margin: "0 auto",
    ">span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      width: 30,
      height: 30,
      backgroundColor: theme.palette.gray[100],
      ".svg-box": {
        width: 20,
        height: 20,
      },
    },
  },

  ">div": {
    color: theme.palette.gray[600],
    fontSize: 14,
    marginTop: 12,
    span: {
      fontSize: 16,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));
