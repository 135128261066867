import { useMemo, useState } from "react";
import { useGlobalStore } from "store/globalStore";
import { truncate } from "utils/helpers";

export default function useUILogic() {
  const user = useGlobalStore((state) => state.user);
  const notifications = useGlobalStore((state) => state.notifications);

  const fullName = useMemo(() => {
    if (user?.full_name?.length > 16) {
      const [first] = user?.full_name?.split(" ");
      if (first?.length <= 16) {
        return first;
      } else {
        return truncate(first, 13);
      }
    }
    return user?.full_name;
  }, [user]);

  // notification
  const [notifsAnchorEl, setNotifsAnchorEl] = useState(null);
  const handleOpenNotifs = (event) => setNotifsAnchorEl(event.currentTarget);
  const handleCloseNotifs = () => setNotifsAnchorEl(null);

  // porfile
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const handleOpenProfile = (event) => setProfileAnchorEl(event.currentTarget);
  const handleCloseProfile = () => setProfileAnchorEl(null);

  // alert
  const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  const handleOpenAlert = (event) => setAlertAnchorEl(event.currentTarget);
  const handleCloseAlert = () => setAlertAnchorEl(null);

  return {
    user,
    notifications,
    fullName,
    //
    notifsAnchorEl,
    handleOpenNotifs,
    handleCloseNotifs,
    //
    profileAnchorEl,
    handleOpenProfile,
    handleCloseProfile,
    //
    alertAnchorEl,
    handleOpenAlert,
    handleCloseAlert,
  };
}
