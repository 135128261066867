import { styled } from "@mui/material/styles";

export const StyledWrapper = styled("div")(({ theme }) => ({
  ".MuiInputBase-root": {
    borderRadius: "5px",
    "&:has([readonly])": {
      cursor: "pointer",
    },
    input: {
      cursor: "inherit",
    },
    fieldset: {
      borderColor: `#D6D6D6 !important`,
      borderRadius: "5px !important",
    },
  },
  // make higher priority
  ".MuiInputBase-root.MuiAutocomplete-inputRoot": {
    padding: "15px",
  },

  ".MuiAutocomplete-endAdornment": {
    left: 9,
    right: "unset !important",
  },

  ".MuiAutocomplete-hasPopupIcon": {
    ".MuiInputBase-root": {
      paddingLeft: 39,
      paddingRight: "9px !important",
    },
  },

  ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
    ".MuiInputBase-root": {
      paddingLeft: 65,
      paddingRight: 9,
    },
  },

  ">label": {
    marginBottom: 8,
  },

  ".MuiFormHelperText-root": {
    textAlign: "right",
  },
}));
