import { useForm } from "react-hook-form";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic({ setStep, setSavedData }) {
  const methods = useForm();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.forgetPassword,
    method: "post",
  });

  const onSubmit = async (params) => {
    try {
      const res = await mutate({ data: { params } });
      setStep("pin");
      setSavedData({
        pinToken: res.data.data?.token,
        email: params.email,
      });
    } catch (error) {}
  };

  return {
    methods,
    isLoading,
    onSubmit,
  };
}
