import { styled } from "@mui/material/styles";

export const StyledBox = styled("div")(({ theme, status }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  paddingLeft: 28,
  position: "relative",
  top: -4,
  pointerEvents: status === "sent" ? "auto" : "none",
  opacity: status === "enter_phone" || status === "time_out" ? 0.5 : 1,
  color:
    status === "successful"
      ? theme.palette.primary.main
      : theme.palette.gray.main,

  ">div": {
    display: "flex",
    justifyContent: "space-between",
    ">div:first-of-type": {
      height: 38,

      ".MuiBackdrop-root": {
        borderRadius: 4,
        ".MuiCircularProgress-root": {
          width: "20px !important",
          height: "20px !important",
        },
      },

      input: {
        color: "inherit",
        border: 0,
        borderBottom: "1px dashed currentColor",
        padding: "9px 0",
        fontSize: 16,
        width: "38px !important",
        outline: "none",
        "&:not(:last-of-type)": {
          marginRight: 20,
        },
      },
    },
    ">div:last-of-type": {
      ".svg-box": {
        width: 40,
        height: 40,
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: 14,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingLeft: 0,
    top: 0,
    marginTop: 34,
  },
}));
