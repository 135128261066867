import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

export default function RHKRadio({ name, rules, options, disabled, ...other }) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  return (
    <FormControl>
      <RadioGroup {...field} {...other}>
        {options.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText error>
          {error?.message || "* This field is required"}
        </FormHelperText>
      )}
    </FormControl>
  );
}

RHKRadio.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
