import { useEffect, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";
import { fDate1 } from "utils/formatTime";

export default function useUILogic() {
  const methods = useForm();

  const { profileData, refetchProfile } = useOutletContext();

  useEffect(() => {
    resetDefaultValues();
  }, [profileData]);

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.profile.updateInfo,
    method: "put",
  });

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          birthday: fDate1(params.birthday),
          state: params.state?.value,
        },
      };
      await mutate({ data });
      enqueueSnackbar("Saved successfully", { variant: "success" });
      refetchProfile({ showLoading: false, callStartup: true });
    } catch (error) {}
  };

  const resetDefaultValues = () => {
    if (profileData) {
      methods.reset({
        gender: profileData?.gender,
        birthday: profileData?.birthday
          ? new Date(profileData?.birthday)
          : null,
        state: profileData?.state,
        address: profileData?.address || "",
      });
    }
  };

  const genderList = useMemo(
    () => [
      {
        label: "Male",
        value: "male",
      },
      {
        label: "Female",
        value: "female",
      },
    ],
    []
  );

  return {
    methods,
    isLoading,
    onSubmit,
    onCancel: resetDefaultValues,
    genderList,
  };
}
