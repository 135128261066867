import { styled } from "@mui/material/styles";
import { InputAdornment } from "@mui/material";

export const StyledName = styled("div")(({ theme }) => ({
  width: 692,
  margin: "20px 0 60px",
  ".MuiFormControl-root": {
    ".MuiFormLabel-root": {
      "&.Mui-disabled": {
        color: "#837A88",
      },
    },
    ".MuiInputBase-root": {
      paddingRight: "9px !important",
      backgroundColor: "#ffffff !important",
      height: 48,
      "&.Mui-disabled": {
        input: {
          WebkitTextFillColor: "#20102B",
        },
        fieldset: {
          borderColor: "#837A88",
        },
      },

      ".MuiInputAdornment-positionEnd": {
        height: "unset",
        maxHeight: "unset",
        ".MuiRating-root": {
          ".svg-box": {
            width: 20,
            height: 20,
          },
        },
        ">button:first-of-type": {
          marginLeft: 8,
          fontWeight: theme.typography.fontWeightRegular,
          "&:not(.Mui-disabled)": {
            backgroundColor: `${theme.palette.primary[50]} !important`,
            "&:hover": {
              backgroundColor: `${theme.palette.primary[100]} !important`,
            },
          },
        },
        ">button:last-of-type": {
          ".svg-box": {
            color: "#000000",
            width: 16,
            height: 16,
          },
        },

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          marginTop: 18,
          marginBottom: 7,
          paddingRight: 16,
          justifyContent: "space-between",
          ">button:last-of-type": {
            position: "absolute",
            top: 4,
            right: 4,
          },
        },
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        height: "unset",
        input: {
          width: "100%",
        },
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    width: "unset",
    margin: "20px 0 16px",
  },
}));
