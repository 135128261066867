import { IconButton } from "@mui/material";
import SvgBox from "components/svg-box";
import DeleteModal from "components/modals/delete-modal";
import InfoBox from "../info-box";
import AddNew from "../add-new";
import WorkExperienceFormModal from "../work-experience-form-modal";
import useUILogic from "./logic";
import { StyledHead } from "./styles";

export default function WorkExperienceList() {
  const {
    work_experiences,
    //
    methods,
    formMode,
    openFormModal,
    toggleFormModal,
    onAddClick,
    onEditClick,
    //
    openDeleteModal,
    toggleDeleteModal,
    onDelete,
    isLoadingDelete,
  } = useUILogic();

  return (
    <>
      <StyledHead>
        <div>Work Experience List</div>
        <IconButton>
          <SvgBox src="/assets/icons/panel/profile/ic_arrow.svg" />
        </IconButton>
      </StyledHead>

      <AddNew onClick={() => onAddClick()} />

      {work_experiences?.map((item) => (
        <InfoBox
          key={item.id}
          item={item}
          type="work"
          onEditClick={() => onEditClick(item)}
          onDeleteClick={() => toggleDeleteModal(item.id)}
        />
      ))}

      <WorkExperienceFormModal
        open={openFormModal}
        toggle={toggleFormModal}
        methods={methods}
        formMode={formMode}
      />

      <DeleteModal
        open={!!openDeleteModal}
        toggle={toggleDeleteModal}
        onDelete={onDelete}
        isLoading={isLoadingDelete}
      >
        Are you sure you want to delete this work experience?
      </DeleteModal>
    </>
  );
}
