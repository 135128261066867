import { FormProvider } from "react-hook-form";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Modal from "components/modal";
import SvgBox from "components/svg-box";
import RHKTextField from "components/forms/rhk-text-field";
import RHKCustomDropzone from "components/forms/rhk-custom-dropzone";
import useUILogic from "./logic";
import { StyledFile, StyledUpload } from "./styles";

export default function CertificateFormModal({
  open,
  toggle,
  methods,
  formMode,
}) {
  const { isLoading, onSubmit } = useUILogic({ toggle, formMode, methods });

  return (
    <Modal
      open={open}
      handleClose={() => toggle()}
      maxWidth="sm"
      title={
        <Box
          sx={{
            p: "12px",
            border: "1px solid",
            borderColor: "gray.200",
            borderRadius: "10px",
            width: 48,
            height: 48,
          }}
        >
          <SvgBox src="/assets/icons/panel/profile/ic_flag.svg" />
        </Box>
      }
    >
      <Typography color="gray_iron.800" fontWeight="medium" fontSize={18}>
        {formMode === "add" ? "Add" : "Edit"} certificate
      </Typography>

      <Typography color="gray.600" mt={1} mb={3}>
        Share where you’ve educated on your profile.
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Grid container rowSpacing={{ xs: 2, md: 2.5 }} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <RHKTextField
                name="title"
                label="Title"
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHKTextField
                name="issuer"
                label="Issuer"
                rules={{ required: true }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12} borderRadius="16px">
              <RHKCustomDropzone
                name="url"
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                  "application/pdf": [],
                  "application/zip": [],
                }}
              >
                {({ error }) => {
                  const file = methods.watch("url");

                  const dotIndex = file?.fileURL?.lastIndexOf(".");
                  const format =
                    dotIndex > -1 ? file?.fileURL?.substring(dotIndex + 1) : "";

                  return !file ? (
                    <StyledUpload>
                      <span>
                        <span>
                          <SvgBox src="/assets/icons/common/ic_upload_cloud.svg" />
                        </span>
                      </span>
                      <div>
                        <span>Click to upload</span> or drag and drop
                        <br />
                        PDF, JPG, PNG or ZIP
                      </div>
                      {error && (
                        <Typography color="error" fontSize={12} mt={1}>
                          Something went wrong
                        </Typography>
                      )}
                    </StyledUpload>
                  ) : (
                    <StyledFile>
                      <span>
                        <span>
                          <SvgBox
                            src={`/assets/icons/panel/profile/ic_${
                              format === "jpg" ||
                              format === "jpeg" ||
                              format === "png"
                                ? "image"
                                : "file"
                            }.svg`}
                          />
                        </span>
                      </span>
                      <div>
                        <span>
                          <Box component="span" textTransform="uppercase">
                            {format}
                          </Box>{" "}
                          File
                        </span>
                        <span>
                          <a
                            href={file.fileURL}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                          >
                            view file
                          </a>
                        </span>
                      </div>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          methods.setValue("url", null);
                        }}
                      >
                        <SvgBox src="/assets/icons/common/ic_delete.svg" />
                      </IconButton>
                    </StyledFile>
                  );
                }}
              </RHKCustomDropzone>
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "100%" }}
              >
                {formMode === "add" ? "Add" : "Update"}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Modal>
  );
}
