export const DEV_BACKEND_URL = "https://api.sirajerp.com";

export const DEV_BACKEND_URL_API = "https://api.sirajerp.com/api/method";

export const FILE_SERVER_ADDRESS = "https://dev.selectyourvilla.com:2053";

export const Panel_Routes = Object.freeze({
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    registerTutor: "/auth/register/tutor",
    forgetPassword: "/auth/forget-password",
  },
  campaign: {
    register: "/campaign/register",
  },
  dashboard: "/dashboard",
  profile: {
    index: "/profile",
    general: "/profile/general",
    careerInfo: "/profile/career-info",
    interpersonalSkills: "/profile/interpersonal-skills",
  },
  wallet: "/wallet",
  settings: "/settings",
});

export const Panel_API_Routes = Object.freeze({
  auth: {
    login: "enjaz.api.v1.auth.login",
    register: "enjaz.api.v1.auth.register",
    confirmRegister: "enjaz.api.v1.auth.confirm_register",
    forgetPassword: "enjaz.api.v1.auth.forget_password",
    forgetPasswordPin: "enjaz.api.v1.auth.forget_password_pin",
    resetPassword: "enjaz.api.v1.auth.reset_password",
  },
  campaign: {
    register: "enjaz.api.v1.campaign.register",
  },
  user: {
    startup: "enjaz.api.v1.user.startup",
    account: "enjaz.api.v1.user.account",
    requestMobileVerification: "enjaz.api.v1.user.request_mobile_verification",
    confirmMobile: "enjaz.api.v1.user.confirm_mobile",
    changePassword: "enjaz.api.v1.user.change_password",
    dashboard: "enjaz.api.v1.user.dashboard",
  },
  profile: {
    getInfo: "enjaz.api.v1.profile.info",
    updateInfo: "enjaz.api.v1.profile.info",
    languages: {
      add: "enjaz.api.v1.profile.languages",
      delete: "enjaz.api.v1.profile.languages",
    },
    skills: {
      add: "enjaz.api.v1.profile.skills",
      delete: "enjaz.api.v1.profile.skills",
    },
    workExperiences: {
      add: "enjaz.api.v1.profile.work_experiences",
      edit: "enjaz.api.v1.profile.work_experiences",
      delete: "enjaz.api.v1.profile.work_experiences",
    },
    educations: {
      add: "enjaz.api.v1.profile.educations",
      edit: "enjaz.api.v1.profile.educations",
      delete: "enjaz.api.v1.profile.educations",
    },
    certificates: {
      add: "enjaz.api.v1.profile.certificates",
      edit: "enjaz.api.v1.profile.certificates",
      delete: "enjaz.api.v1.profile.certificates",
    },
  },
  resources: {
    languages: "enjaz.api.v1.resources.languages",
    skills: "enjaz.api.v1.resources.skills",
    location: "enjaz.api.v1.resources.location",
    departments: "enjaz.api.v1.resources.departments",
    campaignRegister: "enjaz.api.v1.resources.campaign_register",
  },
  wallet: {
    getInfo: "enjaz.api.v1.wallet.info",
  },
  storage: {
    upload: "enjaz.api.v1.storage.upload",
  },
});
