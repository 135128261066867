import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes, Panel_Routes } from "routes/path";

export default function useUILogic({ savedData }) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const { isLoading, mutate, status } = useAxios({
    url: Panel_API_Routes.auth.confirmRegister,
    method: "post",
  });

  const onChange = async (value) => {
    setOtp(value);
  };

  const onSend = async () => {
    if (otp?.length === 4) {
      try {
        const data = {
          params: {
            token: savedData?.token,
            pin: otp,
          },
        };

        await mutate({ data });

        setTimeout(() => {
          navigate(Panel_Routes.auth.login);
        }, 3000);
      } catch (error) {}
    }
  };

  return {
    otp,
    isLoading,
    onChange,
    status,
    onSend,
  };
}
