import { styled } from "@mui/material/styles";

export const StyledLogo = styled("div")(({ theme, wide }) => ({
  padding: wide ? "38px 12px 0 12px" : "38px 0 0 12px",
  color: "#ffffff",
  div: {
    paddingRight: wide ? 0 : 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "[data-image]": {
      minWidth: wide ? 81 : 18,
      maxWidth: wide ? 81 : 18,
      height: 32,
      "path[fill=black]": {
        fill: "#ffffff",
      },
    },
    "[data-arrow]": {
      padding: wide ? "5px 5px 5px 12px" : "8px 5px",
      cursor: "pointer",
      span: {
        transform: `rotate(${wide ? 225 : 45}deg)`,
        position: "relative",
        width: 12,
        height: 12,
        display: "block",
        "&:before": {
          content: '""',
          position: "absolute",
          backgroundColor: "#ffffff",
          borderRadius: 3,
          top: 0,
          left: 0,
          height: 3,
          width: "100%",
        },
        "&:after": {
          content: '""',
          position: "absolute",
          backgroundColor: "#ffffff",
          borderRadius: 3,
          top: 0,
          right: 0,
          height: "100%",
          width: 3,
        },
      },
    },
  },
  hr: {
    margin: "22px 0 36px",
  },
}));

export const StyledList = styled("div")(({ theme, wide }) => ({
  padding: "12px",
  paddingRight: wide ? 12 : 0,
  paddingLeft: wide ? 36 : 12,
  ">ul": {
    paddingRight: wide ? 0 : 12,
  },
}));
