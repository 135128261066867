import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SupportingText from "components/forms/supporting-text";
import SvgBox from "components/svg-box";
import Spin from "components/spin";
import useUILogic from "./logic";
import { StyledMultiSelect } from "./styles";

export default function LanguageSection() {
  const {
    languages,
    //
    dataGet,
    isLoadingGet,
    onOpen,
    //
    isLoadingChange,
    onChange,
    handleDelete,
  } = useUILogic();

  return (
    <div>
      <Typography
        color="gray_iron.800"
        fontSize={18}
        fontWeight="medium"
        mb={3}
      >
        Language
      </Typography>

      <SupportingText text="What languages do you know?">
        <StyledMultiSelect
          component={Spin}
          isLoading={isLoadingChange}
          progressProps={{ sx: { color: "#ffffff" } }}
        >
          <Autocomplete
            multiple
            options={dataGet?.list || []}
            value={languages || []}
            filterSelectedOptions
            onOpen={onOpen}
            onChange={onChange}
            fullWidth
            disableClearable
            isOptionEqualToValue={
              (option, value) => option?.label === value?.label // "value" isn't same
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: isLoadingGet ? (
                    <InputAdornment position="start">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  ) : null,
                }}
                label="Language"
                placeholder="search language..."
              />
            )}
          />
          <div>
            {languages?.map(({ value, label }) => (
              <div key={value}>
                {label}
                <IconButton onClick={() => handleDelete(value)}>
                  <SvgBox src="/assets/icons/common/ic_close.svg" />
                </IconButton>
              </div>
            ))}
          </div>
        </StyledMultiSelect>
      </SupportingText>
    </div>
  );
}
