import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

export const StyledSubLink = styled(({ wide, ...other }) => (
  <NavLink {...other} />
))(({ theme, wide }) => ({
  padding: "8px 0 8px 6px",
  display: "flex",
  alignItems: "center",
  position: "relative",
  color: "#ffffff",
  transition: theme.transitions.create(),
  minHeight: 40,
  borderRadius: 12,

  "[data-cube]": {
    minWidth: 8,
    height: 8,
    borderRadius: 2,
    backgroundColor: theme.palette.primary[100],
    marginRight: 12,
    transition: theme.transitions.create(),
  },

  "& [data-title]": {
    whiteSpace: "nowrap",
  },

  "&.active": {
    "[data-cube]": {
      backgroundColor: theme.palette.primary[300],
      border: `1px solid ${theme.palette.primary[500]}`,
    },
  },

  "&:hover": {
    paddingLeft: 14,
    backgroundColor: "#188162",
  },
}));
