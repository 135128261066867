import { styled } from "@mui/material/styles";

export const StyledRoot = styled("div")(({ theme }) => {
  // console.log("theme", theme);
  return {
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",

    main: {
      flexGrow: 1,
      overflow: "auto",
      minHeight: "100%",
      paddingTop: theme.mixins.toolbar.height + theme.mixins.toolbar.top,

      "&>div": {
        width: "100%",
        height: "100%",
        padding: "24px 24px",
        display: "flex",
        flexDirection: "column",
      },

      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.mixins.toolbar.height_mobile,
        paddingBottom: theme.mixins.navbar.height_mobile,
        "&>div": {
          padding: "24px 16px",
        },
      },
    },
  };
});

export const StyledLoading = styled("div")(({ theme }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  zIndex: 1399,
  background: "#00000066",
  backdropFilter: "blur(10px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
