import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { STORAGE_KEY_USER } from "../../../config";
import useAxios from "hooks/useAxios";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const methods = useForm();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isLoading, mutate } = useAxios({
    url: Panel_API_Routes.auth.login,
    method: "post",
  });

  const onSubmit = async (params) => {
    try {
      const res = await mutate({ data: { params } });

      localStorage.setItem(
        STORAGE_KEY_USER,
        JSON.stringify(res.data.data.user)
      );

      enqueueSnackbar("Login was successful", { variant: "success" });
      navigate(searchParams.get("next") || "/");
    } catch (error) {}
  };

  return {
    methods,
    isLoading,
    onSubmit,
  };
}
