import { useOutletContext } from "react-router-dom";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // get list
  const {
    data: dataGet,
    isLoading: isLoadingGet,
    mutate: mutateGet,
  } = useAxios({
    url: Panel_API_Routes.resources.departments,
  });
  const onOpen = () => {
    if (!dataGet?.list?.length) mutateGet();
  };

  // edit
  const { isLoading: isLoadingEdit, mutate: mutateEdit } = useAxios({
    url: Panel_API_Routes.profile.updateInfo,
    method: "put",
  });

  const onChange = async (event, newValue) => {
    try {
      const data = {
        params: {
          department: newValue?.value || null,
        },
      };
      await mutateEdit({ data });
      refetchProfile({ showLoading: false, callStartup: true });
    } catch (error) {}
  };

  return {
    department: profileData?.department,
    //
    dataGet,
    isLoadingGet,
    onOpen,
    //
    isLoadingEdit,
    onChange,
  };
}
