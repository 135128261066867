import { FormProvider } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKPassword from "components/forms/rhk-password";
import SupportingText from "components/forms/supporting-text";
import useUILogic from "./logic";

export default function ChangePassword() {
  const { methods, isLoading, onSubmit, onCancel } = useUILogic();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Typography
          color="gray_iron.800"
          fontSize={18}
          fontWeight="medium"
          mt={{ xs: 3, md: 5 }}
        >
          Password
        </Typography>
        <Typography color="gray_iron.500" mt={1}>
          Please enter your current password to change your password.
        </Typography>

        <Divider sx={{ mt: { xs: 2, md: 3 } }} />

        <Grid container rowSpacing={{ xs: 3, md: 5 }}>
          <Grid item xs={12} />

          <Grid item xs={12} md={9} xl={8}>
            <SupportingText text="Enter your old password here">
              <RHKPassword
                name="old_password"
                label="Current password"
                rules={{ required: true }}
                disabled={isLoading}
              />
            </SupportingText>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={9} xl={8}>
            <SupportingText text="Choose new password">
              <RHKPassword
                name="new_password"
                label="New password"
                rules={{ required: true, deps: ["confirm_new_password"] }}
                disabled={isLoading}
              />
            </SupportingText>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={9} xl={8}>
            <SupportingText text="Confirm your new password">
              <RHKPassword
                name="confirm_new_password"
                label="Confirm new password"
                rules={{
                  required: true,
                  validate: (value, formValues) =>
                    value === formValues.new_password ||
                    "* Confirm password not matched !",
                }}
                disabled={isLoading}
              />
            </SupportingText>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item ml="auto">
            <Button
              variant="outlined"
              color="gray"
              sx={{
                minWidth: { md: 185 },
                color: "gray_iron.800",
                mr: { xs: 2, md: 4 },
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              sx={{ minWidth: { md: 185 } }}
            >
              Update password
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
