export default function Tabs(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "unset",
        },
        indicator: {
          display: "none",
        },
      },
      defaultProps: {
        variant: "scrollable",
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "unset",
          fontSize: 18,
          color: "#157458",
          fontWeight: theme.typography.fontWeightRegular,
          textTransform: "none",
          border: "1px solid #F4FEFB",
          padding: "12px 16px",
          marginRight: 0,
          backgroundColor: "#ffffff",
          "&:first-of-type": {
            borderRadius: "24px 0 0 24px",
          },
          "&:last-of-type": {
            borderRadius: "0 24px 24px 0",
          },
          "&:not(:first-of-type)": {
            borderLeft: 0,
          },
          "&:not(:last-of-type)": {
            borderRightColor: "#157458",
          },
          "&.Mui-selected": {
            color: "#157458",
            backgroundColor: "#F4FEFB",
          },

          [theme.breakpoints.down("sm")]: {
            fontSize: 14,
            padding: "6px 12px",
          },
        },
      },
    },
  };
}
