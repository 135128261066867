export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          margin: "0 8px 0 11px",
          width: 36,
          height: 20,
          padding: 0,
          borderRadius: 10,
          ".MuiSwitch-switchBase": {
            padding: 3,
            color: "#fff",
            "&.Mui-checked": {
              color: "#fff",
              transform: "translateX(16px)",
              "+.MuiSwitch-track": {
                backgroundColor: theme.palette.primary[500],
              },
            },
          },
          ".MuiSwitch-thumb": {
            width: 14,
            height: 14,
          },
          ".MuiSwitch-track": {
            opacity: "1 !important",
            backgroundColor: theme.palette.gray[400],
          },
        },
      },
    },
  };
}
