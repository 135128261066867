import { Navigate, useLocation } from "react-router-dom";
import useLocalStorage from "hooks/useLocalStorage";
import { STORAGE_KEY_USER } from "../config";

export default function AuthGuard({ children }) {
  const [user] = useLocalStorage(STORAGE_KEY_USER);

  const { pathname } = useLocation();

  if (!user) {
    return <Navigate to={`/auth/login?next=${pathname}`} replace />;
  }

  return children;
}
