import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// date picker
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "components/scroll-to-top";
import SnackbarProvider from "components/snackbar-provider";
import ErrorBoundary from "./ErrorBoundary";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ErrorBoundary>
          <ThemeProvider>
            <ScrollToTop />
            <SnackbarProvider />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </HelmetProvider>
  );
}
