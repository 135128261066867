import { styled } from "@mui/material/styles";

export const StyledAuth = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",

  ">span:first-of-type": {
    position: "absolute",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ">div:first-of-type": {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(5px)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  ">.svg-box": {
    margin: "0 0  67px",
    width: 191,
    height: 75,
    position: "relative",
    "path[fill=black]": {
      fill: "#DADADA",
    },
  },

  [theme.breakpoints.down("sm")]: {
    minHeight: "100%",
    justifyContent: "unset",
    backgroundImage: "none",
    backgroundColor: "#ffffff",
    ">.svg-box": {
      margin: "70px 0 90px",
      width: 95,
      height: 37,
    },
  },
}));

export const StyledContent = styled("div")(({ theme }) => ({
  minWidth: 550,
  maxWidth: 550,
  borderRadius: 16,
  backgroundColor: "#ffffffb3",
  padding: "68px 24px",
  textAlign: "center",
  border: "1px solid #ffffff",
  backdropFilter: "blur(25px)",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("sm")]: {
    minWidth: "unset",
    maxWidth: "unset",
    padding: "0 16px",
    width: "100%",
    borderRadius: 0,
    flex: 1,
  },

  h1: {
    fontSize: 40,
    fontWeight: theme.typography.fontWeightMedium,
    color: "#51525C",
    marginBottom: 78,
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
  },

  button: {
    fontWeight: theme.typography.fontWeightRegular,
  },

  ">a": {
    button: {
      marginTop: 24,
      backgroundColor: `${theme.palette.primary[50]} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.primary[100]} !important`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto 0 32px",
    },
  },
}));
