import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

export default function SupportingText({ text, children }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Typography
        color="gray_iron.600"
        sx={{
          width: { xs: "100%", md: "50%" },
          mb: { xs: 2, md: 0 },
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

SupportingText.propTypes = {
  text: PropTypes.node,
  children: PropTypes.node,
};
