import Autocomplete from "./Autocomplete";
import Button from "./Button";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Menu from "./Menu";
import Radio from "./Radio";
import Rating from "./Rating";
import Switch from "./Switch";
import Tabs from "./Tabs";
import TextField from "./TextField";

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Autocomplete(theme),
    Button(theme),
    Checkbox(theme),
    DatePicker(theme),
    Menu(theme),
    Radio(theme),
    Rating(theme),
    Switch(theme),
    Tabs(theme),
    TextField(theme)
  );
}
