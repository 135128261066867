import { useState } from "react";

// ----------------------------------------------------------------------

export default function useTabs(defaultValue) {
  const [currentTab, setCurrentTab] = useState(defaultValue);

  const onChangeTab = (event, newValue) => setCurrentTab(newValue);

  return [currentTab, onChangeTab, setCurrentTab];
}
