import { styled } from "@mui/material/styles";

export const StyledInfo = styled("div")(({ theme }) => ({
  marginTop: 24,
  padding: "28px 48px 28px 24px",
  border: `1px solid ${theme.palette.gray[300]}`,
  borderRadius: 16,
  color: theme.palette.gray[600],
  position: "relative",
  ">div": {
    div: {
      fontSize: 20,
      fontWeight: theme.typography.fontWeightMedium,
    },
    span: {
      marginTop: 12,
      display: "block",
    },
  },
  ">button": {
    position: "absolute",
    top: 8,
    right: 8,
    borderRadius: 8,
  },

  [theme.breakpoints.down("sm")]: {
    padding: "16px 40px 16px 16px",
    ">button": {
      right: 0,
    },
  },
}));
