import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Modal from "components/modal";

export default function DeleteModal({
  toggle,
  children,
  onDelete,
  isLoading,
  ...other
}) {
  return (
    <Modal handleClose={() => toggle()} maxWidth="xs" {...other}>
      {children}

      <Box sx={{ display: "flex", mt: 4 }}>
        <Button sx={{ flexGrow: 1, margin: "0 8px" }} onClick={() => toggle()}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          sx={{ flexGrow: 1, margin: "0 8px" }}
          loading={isLoading}
          onClick={() => onDelete()}
        >
          Delete
        </LoadingButton>
      </Box>
    </Modal>
  );
}
