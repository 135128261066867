import { closeSnackbar } from "notistack";
import SvgBox from "components/svg-box";
import { StyledSnack } from "./styles";

export default function SnackbarProvider() {
  return (
    <StyledSnack
      preventDuplicate
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      autoHideDuration={7000}
      action={(id) => (
        <div data-action onClick={() => closeSnackbar(id)}>
          <SvgBox src="/assets/icons/common/ic_close.svg" />
          <div />
        </div>
      )}
    />
  );
}
