import { NavLink } from "react-router-dom";
import SvgBox from "components/svg-box";
import BlinkingBadge from "components/blinking-badge";
import useUILogic from "./logic";
import { StyledBox, StyledList } from "./styles";

export default function NavMobile() {
  const { sideMenu } = useUILogic();

  return (
    <StyledBox>
      <StyledList>
        {sideMenu?.map(({ title, path, icon, blinking_badge }) => (
          <li key={title}>
            <NavLink to={path}>
              <SvgBox src={`/assets/icons/navbar/${icon}.svg`} />
              <SvgBox
                src={`/assets/icons/navbar/${icon}_active.svg`}
                component="img"
              />
              <span data-title>
                {title?.length > 16 ? title?.split(" ")[0] : title}
              </span>
              {blinking_badge && (
                <BlinkingBadge
                  size={12}
                  sx={{
                    position: "absolute",
                    right: "15%",
                    top: "calc(50% - 24px + 6px)",
                  }}
                />
              )}
            </NavLink>
          </li>
        ))}
      </StyledList>
    </StyledBox>
  );
}
