import useTabs from "hooks/useTabs";

export default function useUILogic() {
  const [currentTab, onChangeTab] = useTabs("general_info");

  return {
    currentTab,
    onChangeTab,
  };
}
