import { styled } from "@mui/material/styles";

export const StyledTable = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5,auto)",
  overflowX: "auto",

  ">strong": {
    padding: "20px",
    backgroundColor: theme.palette.gray[50],
    fontWeight: theme.typography.fontWeightMedium,
  },

  ">div": {
    borderTop: `1px solid ${theme.palette.gray[200]}`,
    padding: "24px 20px",

    "&[data-amount]": {
      display: "flex",
      alignItems: "center",
      ">span:last-of-type": {
        width: 26,
        height: 16,
        backgroundColor: theme.palette.success[50],
        color: theme.palette.success[500],
        borderRadius: 16,
        marginLeft: 12,
        textAlign: "center",
        ".svg-box": {
          width: 16,
          height: 16,
        },
      },
    },
  },
}));
