import { FormProvider } from "react-hook-form";
import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKRadio from "components/forms/rhk-radio";
import RHKDatePicker from "components/forms/rhk-date-picker";
import RHKSelectCountry from "components/forms/rhk-select-country";
import RHKTextField from "components/forms/rhk-text-field";
import SupportingText from "components/forms/supporting-text";
import useUILogic from "./logic";

export default function ProfileGeneralPage() {
  const { methods, isLoading, onSubmit, onCancel, genderList } = useUILogic();

  return (
    <Card
      sx={{
        p: {
          xs: "24px 16px",
          md: "32px 32px 40px",
        },
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <Typography color="gray.600" mb={3}>
            Some description here to help the student. or we can remove it
            completly
          </Typography>

          <Grid container rowSpacing={{ xs: 3, md: 5 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              container
              flexDirection="column"
              justifyContent="center"
              xs={12}
              md={4.5}
              xl={4}
            >
              <Grid item>
                <Typography
                  color="gray_iron.800"
                  fontWeight="medium"
                  fontSize={18}
                >
                  Gender
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="gray.600" mt={1}>
                  Choose your gender
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4.5} xl={4}>
              <RHKRadio
                name="gender"
                rules={{ required: true }}
                options={genderList}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} md={9} xl={8}>
              <SupportingText text="Birthday(Optional)">
                <RHKDatePicker
                  name="birthday"
                  label="Date of birth"
                  maxDate={new Date()}
                  disabled={isLoading}
                />
              </SupportingText>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} md={9} xl={8}>
              <SupportingText text="Choose state">
                <RHKSelectCountry
                  name="state"
                  label="State"
                  query="state"
                  rules={{ required: true }}
                  disabled={isLoading}
                />
              </SupportingText>
            </Grid>

            <Grid item xs={12} md={9} xl={8}>
              <SupportingText text="Your address">
                <RHKTextField
                  name="address"
                  label="Address"
                  rules={{ required: true }}
                  disabled={isLoading}
                />
              </SupportingText>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item ml="auto">
              <Button
                variant="outlined"
                color="gray"
                sx={{
                  minWidth: { md: 185 },
                  color: "gray_iron.800",
                  mr: { xs: 2, md: 4 },
                }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ minWidth: { md: 185 } }}
              >
                Save changes
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Card>
  );
}
