import { styled } from "@mui/material/styles";
import { AppBar } from "@mui/material";

export const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  width: `calc(100% - ${theme.mixins.navbar.width}px - 32px)`,
  right: 16,
  backgroundColor: theme.palette.background.default,

  ".MuiToolbar-root": {
    minHeight: "unset",
    backgroundColor: "#ffffff",
    borderRadius: 16,
    justifyContent: "space-between",
    gap: 20,
    padding: "0 44px",
    color: theme.palette.primary.main,
    boxShadow: "0px 2px 6px -4px #000",

    ">div": {
      display: "flex",
      alignItems: "center",
      gap: 20,
      height: "100%",
      "&:first-of-type": {
        flexGrow: 1,
      },
    },

    [theme.breakpoints.down(1555)]: {
      padding: "0 20px",
    },
    [theme.breakpoints.down("xl")]: {
      gap: 18,
      ">div": {
        gap: 18,
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "0 12px",
    },
    [theme.breakpoints.down("sm")]: {
      height: theme.mixins.toolbar.height_mobile,
      top: 0,
      borderRadius: 0,
      ">div": {
        "&:first-of-type": {
          justifyContent: "space-between",
        },
      },
    },
  },

  [theme.breakpoints.down("lg")]: {
    width: `calc(100% - ${theme.mixins.navbar.width_tablet}px - 32px)`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    right: 0,
  },
}));

export const StyledDate = styled("div")(({ theme }) => ({
  letterSpacing: "1.54px",
  fontSize: "1rem",
  color: theme.palette.gray_iron[500],

  [theme.breakpoints.down("xl")]: {
    fontSize: 14,
    letterSpacing: "1.15px",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const StyledSearch = styled("div")(({ theme }) => ({
  backgroundColor: "#EDEDED",
  borderRadius: 16,
  display: "flex",
  alignItems: "center",
  ">.svg-box:first-of-type": {
    minWidth: 24,
    marginLeft: 24,
    color: theme.palette.gray.main,
  },
  ">input": {
    height: 48,
    padding: "0 24px 0 12px",
    border: 0,
    outline: "none",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: theme.palette.gray.main,
      letterSpacing: "0.51px",
    },
  },
  ">.svg-box:last-of-type": {
    display: "none",
    width: 12,
    height: 12,
    margin: 12,
  },

  [theme.breakpoints.down("xl")]: {
    ">.svg-box:first-of-type": {
      marginLeft: 8,
    },
    ">input": {
      padding: "0 8px 0 8px",
    },
  },
  [theme.breakpoints.down("md")]: {
    ">input": {
      maxWidth: 165,
    },
  },
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "transparent",
    height: "100%",
    ">.svg-box:first-of-type": {
      marginLeft: 0,
    },
    ">input": {
      height: "100%",
      maxWidth: 75,
    },
    "&:focus-within": {
      backgroundColor: "#ffffff",
      position: "absolute",
      zIndex: 2,
      width: "100%",
      left: 0,
      ">.svg-box:first-of-type": {
        marginLeft: 12,
        color: theme.palette.primary.main,
      },
      ">input": {
        maxWidth: "unset",
        width: "100%",
      },
      ">.svg-box:last-of-type": {
        display: "block",
      },
    },
  },
}));

export const StyledAlert = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: 15,
  color: theme.palette.warning.main,

  ">button": {
    pointerEvents: "none",
  },

  [theme.breakpoints.down(1430)]: {
    ">button": {
      pointerEvents: "auto",
    },
    ">span": {
      display: "none",
    },
  },
}));

export const StyledButtons = styled("div")(({ theme }) => ({
  backgroundColor: "#EDEDED",
  borderRadius: 16,
  display: "flex",

  ">button": {
    borderRadius: 16,
    color: theme.palette.primary[600],
    ">div": {
      padding: "14px 21px",
      ".svg-box": {
        width: 16,
        height: 20,
      },
    },
    "&:first-of-type": {
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: 6,
        right: -1,
        width: 2,
        height: "calc(100% - 12px)",
        backgroundColor: "#ffffff",
        borderRadius: 2,
      },
      [theme.breakpoints.down("sm")]: {
        "&:before": {
          width: 1,
        },
      },
    },
    "&:last-of-type": {
      ".MuiBadge-badge": {
        minWidth: 16,
        maxWidth: 16,
        height: 16,
        top: -4,
        right: -4,
        backgroundColor: theme.palette.primary[600],
        color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        ".MuiBadge-badge": {
          minWidth: 6,
          maxWidth: 6,
          height: 6,
          top: -1,
          right: -1,
          color: "transparent",
          padding: 0,
        },
      },
    },

    [theme.breakpoints.down("sm")]: {
      borderRadius: 8,
      ">div": {
        padding: "6px 13px",
      },
    },
  },

  [theme.breakpoints.down("sm")]: {
    borderRadius: 8,
  },
}));

export const StyledProfile = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  height: "100%",

  ">div:first-of-type": {
    marginRight: 13,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "right",
    ">span": {
      letterSpacing: "1.54px",
      fontSize: 14,
      textTransform: "uppercase",
      color: "#1F003E",
    },
    "&>div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: 4,
      fontSize: 12,
      letterSpacing: "1.15px",
      color: theme.palette.primary[600],
      span: {
        textTransform: "capitalize",
      },
      ">.svg-box": {
        width: 12,
        height: 10,
        marginLeft: 8,
      },
    },

    [theme.breakpoints.down("xl")]: {
      marginRight: 8,
      ">span": {
        letterSpacing: "1.15px",
      },
    },
    [theme.breakpoints.down(950)]: {
      display: "none",
    },
  },
  ">span:last-of-type": {
    width: 60,
    height: 60,
    borderRadius: 60,
    border: `2px solid ${theme.palette.primary.main}`,

    [theme.breakpoints.down("xl")]: {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32,
      borderWidth: 1,
    },
  },
}));
