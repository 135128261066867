import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(({ wide, ...other }) => (
  <NavLink {...other} />
))(({ theme, wide }) => ({
  margin: "6px 0",
  padding: "10px 10px",
  display: "flex",
  alignItems: "center",
  position: "relative",
  color: "#EDEDED",
  fontSize: "1rem",
  borderRadius: 12,
  transition: theme.transitions.create(),
  "&:before": {
    content: '""',
    position: "absolute",
    left: wide ? -26 : -2,
    top: 0,
    width: "4px",
    height: "100%",
    borderRadius: 4,
    backgroundColor: "#ffffff",
    transition: theme.transitions.create("opacity"),
    opacity: 0,
  },

  "img.svg-box": {
    display: "none",
  },

  "&.active": {
    color: "#ffffff",
    "&:before": {
      opacity: 1,
    },
    "img.svg-box": {
      display: "block",
    },
    "span.svg-box": {
      display: "none",
    },
  },

  ".svg-box": {
    minWidth: 24,
  },
  "& [data-title]": {
    display: wide ? "block" : "none",
    marginLeft: 12,
    whiteSpace: "nowrap",
  },

  "&:hover": {
    paddingLeft: wide ? 24 : 20,
    backgroundColor: "#188162",
  },
}));
