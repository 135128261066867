import { useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import useAxios from "hooks/useAxios";
import useModal from "hooks/useModal";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // form
  const methods = useForm();
  const [formMode, setFormMode] = useState("add"); // add, edit
  const [openFormModal, toggleFormModal] = useModal();

  const onAddClick = () => {
    toggleFormModal();
    setFormMode("add");
    methods.reset({
      degree: null,
      institution_name: "",
      field_of_study: "",
      status: null,
      level: "",
      certificate_url: null,
    });
  };

  const onEditClick = (item) => {
    toggleFormModal();
    setFormMode("edit");
    methods.reset({
      id: item.id,
      degree: { label: item.degree, value: item.degree },
      institution_name: item.institution_name,
      field_of_study: item.field_of_study,
      status: { label: item.status, value: item.status },
      level: item.level || "",
      certificate_url: item?.certificate_url
        ? { fileURL: item?.certificate_url, fileName: item?.certificate_url }
        : null,
    });
  };

  // delete
  const [openDeleteModal, toggleDeleteModal] = useModal();
  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useAxios({
    url: Panel_API_Routes.profile.educations.delete,
    method: "delete",
  });
  const onDelete = async () => {
    try {
      const data = {
        params: { id: openDeleteModal },
      };
      await mutateDelete({ data });
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      toggleDeleteModal();
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    educations: profileData?.educations,
    //
    methods,
    formMode,
    openFormModal,
    toggleFormModal,
    onAddClick,
    onEditClick,
    //
    openDeleteModal,
    toggleDeleteModal,
    onDelete,
    isLoadingDelete,
  };
}
