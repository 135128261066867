import { styled } from "@mui/material/styles";

export const StyledCheckList = styled("div")(({ theme }) => ({
  ">a": {
    marginBottom: 28,
    padding: "20px 28px",
    borderRadius: 16,
    backgroundColor: "#F4F4F4",
    color: "#777777",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#e3e3e3",
    },

    ">span": {
      minWidth: 30,
      height: 30,
      borderRadius: 30,
      backgroundColor: "#D9D9D9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 24,
      ".svg-box": {
        width: 14,
        height: 8,
        display: "none",
      },
    },
    ">p": {
      ">span": {
        fontSize: 12,
      },
    },

    "&[data-checked=true]": {
      backgroundColor: "#F4FEFB",
      color: "#1FAE83",
      pointerEvents: "none",
      ">span": {
        backgroundColor: "#157458",
        color: "#ffffff",
        ".svg-box": {
          display: "block",
        },
      },
      ">p": {
        ">span": {
          display: "none",
        },
      },
    },

    [theme.breakpoints.down("xl")]: {
      padding: "20px 20px",
      ">span": {
        marginRight: 18,
      },
    },

    [theme.breakpoints.down("md")]: {
      marginBottom: 24,
      padding: "16px 20px",
      ">span": {
        minWidth: 25,
        height: 25,
      },
    },
  },
}));

export const StyledSliderDots = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 26,
  ">div": {
    cursor: "pointer",
    padding: 6,
    ">span": {
      display: "block",
      width: 16,
      height: 16,
      borderRadius: 16,
      backgroundColor: "#E4E7EC",
      transition: theme.transitions.create(),
    },
    "&[data-active=true]": {
      ">span": {
        backgroundColor: "#29E8AF",
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    ">div": {
      ">span": {
        width: 12,
        height: 12,
      },
    },
  },
}));
