import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const StyledWrapper = styled("div")(({ theme }) => ({
  ".MuiInputBase-root": {
    borderRadius: "5px",
    input: {
      padding: "16.5px 14px !important",
    },
    fieldset: {
      borderColor: `#D6D6D6 !important`,
      borderRadius: "5px !important",
    },
  },

  ">label": {
    marginBottom: 8,
  },

  ".MuiFormHelperText-root": {
    textAlign: "right",
  },
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  ">.MuiFormLabel-root": {
    cursor: "pointer",
    "&.Mui-disabled": {
      cursor: "default",
    },
  },
  ">.MuiInputBase-root": {
    cursor: "pointer",
    paddingRight: 0,

    input: {
      cursor: "pointer",
    },
    ".MuiInputAdornment-positionEnd": {
      color: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      cursor: "default",
      input: {
        cursor: "default",
      },
    },
  },
}));
