import { Link, Outlet } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import PageWrapper from "components/page-wrapper";
import { PROFILE_PAGE_TITLE } from "lib/page-titles";
import useUILogic from "./logic";

export default function ProfileLayout() {
  const {
    currentTab,
    tabs,
    //
    isLoading,
    profileData,
    error,
    refetchProfile,
  } = useUILogic();

  return (
    <PageWrapper
      pageTitle={PROFILE_PAGE_TITLE}
      title="Profile"
      isLoading={isLoading}
      error={error}
    >
      <Tabs
        value={currentTab}
        variant="fullWidth"
        sx={{
          mb: 3,
          display: { xs: "flex", sm: "none" },
        }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} component={Link} {...tab} />
        ))}
      </Tabs>

      <Outlet
        context={{
          profileData,
          refetchProfile,
        }}
      />
    </PageWrapper>
  );
}
