import { forwardRef } from "react";
import PhoneInput from "react-phone-input-2";

const inputComponent = forwardRef(({ value, onChange, ...props }, ref) => (
  <PhoneInput
    value={value}
    onChange={onChange}
    inputProps={{ ...props, ref }}
  />
));

export default inputComponent;
