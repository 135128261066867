import { Link } from "react-router-dom";
import { Box, Card, Grid, Typography } from "@mui/material";
import PageWrapper from "components/page-wrapper";
import Image from "components/image";
import SvgBox from "components/svg-box";
import { DASHBOARD_PAGE_TITLE } from "lib/page-titles";
import { Panel_Routes } from "routes/path";
import useUILogic from "./logic";
import { StyledCheckList, StyledSliderDots } from "./styles";

export default function DashboardPage() {
  const {
    user,
    //
    error,
    data,
    isLoading,
    //
    loaded,
    sliderRef,
    instanceRef,
    currentSlide,
  } = useUILogic();

  return (
    <PageWrapper
      pageTitle={DASHBOARD_PAGE_TITLE}
      title="Dashboard"
      error={error}
      isLoading={isLoading}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          p: { xs: "20px 16px", md: "40px 32px" },
        }}
      >
        <Typography
          fontSize={24}
          fontWeight="medium"
          color="black"
          textTransform="uppercase"
          mb={{ xs: 3, md: 5 }}
        >
          Welcome {user?.full_name}
        </Typography>

        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          sx={{ px: { xl: 4 } }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ maxWidth: { xl: "521px !important" } }}
          >
            <StyledCheckList>
              <Link
                to={Panel_Routes.settings}
                data-checked={data?.check_list?.is_mobile_number_verified}
              >
                <span>
                  <SvgBox src="/assets/icons/common/ic_check.svg" />
                </span>
                <p>
                  Verify Your Mobile Number <span>(click&nbsp;here)</span>
                </p>
              </Link>
              <Link
                to={Panel_Routes.profile.general}
                data-checked={data?.check_list?.is_profile_general_complete}
              >
                <span>
                  <SvgBox src="/assets/icons/common/ic_check.svg" />
                </span>
                <p>
                  Complete your profile’s general section{" "}
                  <span>(click&nbsp;here)</span>
                </p>
              </Link>
              <Link
                to={Panel_Routes.profile.careerInfo}
                data-checked={data?.check_list?.is_profile_career_info_complete}
              >
                <span>
                  <SvgBox src="/assets/icons/common/ic_check.svg" />
                </span>
                <p>
                  Complete your profile’s career info{" "}
                  <span>(click&nbsp;here)</span>
                </p>
              </Link>
              <Link
                to={Panel_Routes.profile.interpersonalSkills}
                data-checked={data?.check_list?.is_profile_skills_complete}
              >
                <span>
                  <SvgBox src="/assets/icons/common/ic_check.svg" />
                </span>
                <p>
                  Complete your profile’s interpersonal Skills{" "}
                  <span>(click&nbsp;here)</span>
                </p>
              </Link>
            </StyledCheckList>
            <Typography
              color="black"
              display="flex"
              alignItems="flex-start"
              pt={1}
            >
              <Box fontSize={28} lineHeight="22px" mr={1}>
                •
              </Box>
              Upon completing your profile and successfully verifying your
              account, please stay tuned for updates on our courses ...
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ maxWidth: { xl: "490px !important" } }}
          >
            <div ref={sliderRef} className="keen-slider">
              {data?.dashboard_sliders?.map((src) => (
                <div key={src} className="keen-slider__slide">
                  <Box>
                    <Image src={src} sx={{ borderRadius: "16px" }} />
                  </Box>
                </div>
              ))}
            </div>

            {loaded &&
              instanceRef.current &&
              instanceRef.current.track.details?.slides?.length > 1 && (
                <StyledSliderDots>
                  {[
                    ...Array(
                      instanceRef.current.track.details.slides.length
                    ).keys(),
                  ].map((idx) => (
                    <div
                      key={idx}
                      onClick={() => instanceRef.current?.moveToIdx(idx)}
                      data-active={currentSlide === idx}
                    >
                      <span />
                    </div>
                  ))}
                </StyledSliderDots>
              )}
          </Grid>
        </Grid>

        <div
          dangerouslySetInnerHTML={{
            __html: data?.dynamic_content,
          }}
        />
      </Card>
    </PageWrapper>
  );
}
