import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import SvgBox from "components/svg-box";
import Image from "components/image";
import { Panel_Routes } from "routes/path";
import { FORGOT_PASSWORD_PAGE_TITLE } from "lib/page-titles";
import ForgetPasswordForm from "./components/forget-password-form";
import ForgetPasswordPin from "./components/forget-password-pin";
import ForgetPasswordReset from "./components/forget-password-reset";
import ForgetPasswordSuccess from "./components/forget-password-success";
import useUILogic from "./logic";
import { StyledAuth, StyledContent } from "./styles";

export default function ForgetPasswordPage() {
  const { step, setStep, savedData, setSavedData } = useUILogic();

  return (
    <>
      <Helmet>
        <title>{FORGOT_PASSWORD_PAGE_TITLE}</title>
      </Helmet>

      <StyledAuth>
        <Image
          src={`${process.env.PUBLIC_URL}/assets/images/auth-background.png`}
        />
        <div />

        <SvgBox src="/assets/logo.svg" component="svg" />

        <StyledContent>
          <h1>
            {step === "form" || step === "pin"
              ? "Forgot password"
              : step === "reset"
              ? "Reset password"
              : "Reset successful"}
          </h1>

          {step === "form" ? (
            <ForgetPasswordForm setStep={setStep} setSavedData={setSavedData} />
          ) : step === "pin" ? (
            <ForgetPasswordPin
              setStep={setStep}
              savedData={savedData}
              setSavedData={setSavedData}
            />
          ) : step === "reset" ? (
            <ForgetPasswordReset setStep={setStep} savedData={savedData} />
          ) : step === "success" ? (
            <ForgetPasswordSuccess />
          ) : null}

          <Link to={Panel_Routes.auth.login}>
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              size="large"
              startIcon={
                <SvgBox
                  src="/assets/icons/auth/ic_arrow_left.svg"
                  sx={{ width: 18, height: 18 }}
                />
              }
            >
              Back to login
            </Button>
          </Link>
        </StyledContent>
      </StyledAuth>
    </>
  );
}
