import { Card, Divider, Typography } from "@mui/material";
import { useGlobalStore } from "store/globalStore";
import EducationList from "./components/education-list";
import CertificateList from "./components/certificate-list";
import WorkExperienceList from "./components/workexperience-list";
import SelectDepartment from "./components/select-department";

export default function ProfileCareerInfoPage() {
  const user = useGlobalStore((state) => state.user);

  return (
    <Card
      sx={{
        p: {
          xs: "24px 16px",
          md: "32px 32px 40px",
        },
      }}
    >
      <Typography color="gray.600">
        Some description here to help the student. or we can remove it completly
      </Typography>

      <Divider sx={{ mt: { xs: 2, md: 3 }, mb: { xs: 3, md: 6 } }} />

      {user?.type === "student" ? (
        <EducationList />
      ) : user?.type === "tutor" ? (
        <>
          <SelectDepartment />
          <Divider sx={{ my: { xs: 3, md: 6 } }} />
          <CertificateList />
        </>
      ) : null}

      <Divider sx={{ my: { xs: 3, md: 6 } }} />

      <WorkExperienceList />
    </Card>
  );
}
