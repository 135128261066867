import { styled } from "@mui/material/styles";

export const StyledWrapper = styled("div")(({ theme }) => ({
  ".MuiInputBase-root": {
    borderRadius: "5px",
    input: {
      padding: "16.5px 14px !important",
    },
    fieldset: {
      borderColor: `#D6D6D6 !important`,
      borderRadius: "5px !important",
    },
  },

  ">label": {
    marginBottom: 8,
  },

  ".MuiFormHelperText-root": {
    textAlign: "right",
  },
}));
