import PropTypes from "prop-types";
import { Popover, Grow, Slide } from "@mui/material";
import Image from "components/image";
import SvgBox from "components/svg-box";
import { Panel_Routes } from "routes/path";
import useUILogic from "./logic";
import {
  StyledAccountSettings,
  StyledContent,
  StyledEmail,
  StyledFAQ,
  StyledLogout,
  StyledProfile,
} from "./styles";

export default function ProfilePopover({
  profileAnchorEl,
  handleCloseProfile,
}) {
  const { user, handleLogout, isMobile } = useUILogic();

  return (
    <Popover
      open={!!profileAnchorEl}
      anchorEl={isMobile ? document.body : profileAnchorEl}
      onClose={handleCloseProfile}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      TransitionComponent={isMobile ? Slide : Grow}
      TransitionProps={{ direction: "up" }}
      PaperProps={{
        sx: {
          mt: "6px",
          ...(isMobile && {
            width: "100%",
            left: "0 !important",
            top: "unset !important",
            bottom: 0,
            maxWidth: "unset",
            borderRadius: "16px 16px 0 0",
          }),
        },
      }}
    >
      <StyledContent>
        {isMobile && (
          <StyledProfile>
            <Image src={user?.avatar} type="avatar" />
            <div>
              <span>{user?.full_name}</span>
              <span>
                {user?.is_service_provider ? "Service Provider" : "Client"}
              </span>
            </div>
          </StyledProfile>
        )}

        <div>
          <SvgBox src="/assets/icons/header/ic_email.svg" />
          <StyledEmail>
            <span>Email</span>
            <span>{user?.email}</span>
          </StyledEmail>
        </div>

        <StyledAccountSettings
          to={Panel_Routes.settings}
          onClick={handleCloseProfile}
        >
          <SvgBox src="/assets/icons/header/ic_settings.svg" />
          <div>Settings</div>
        </StyledAccountSettings>

        <StyledFAQ>
          <SvgBox src="/assets/icons/header/ic_faq.svg" />
          <div>FAQ</div>
        </StyledFAQ>

        <StyledLogout onClick={handleLogout}>
          <SvgBox src="/assets/icons/header/ic_logout.svg" />
          <div>Logout</div>
        </StyledLogout>
      </StyledContent>
    </Popover>
  );
}

ProfilePopover.propTypes = {
  profileAnchorEl: PropTypes.object,
  handleCloseProfile: PropTypes.func,
};
