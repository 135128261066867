import { useState } from "react";
import { InputAdornment } from "@mui/material";
import SvgBox from "components/svg-box";
import RHKTextField from "../rhk-text-field";

export default function RHKPassword(props) {
  const [show, setShow] = useState(false);

  return (
    <RHKTextField
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ cursor: "pointer" }}
            onClick={() => setShow(!show)}
          >
            <SvgBox
              src={`/assets/icons/common/ic_${show ? "eye" : "eye_hide"}.svg`}
              sx={{ width: 18, height: 18 }}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
