import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import SupportingText from "components/forms/supporting-text";
import Spin from "components/spin";
import useUILogic from "./logic";

export default function SelectDepartment() {
  const {
    department,
    //
    dataGet,
    isLoadingGet,
    onOpen,
    //
    isLoadingEdit,
    onChange,
  } = useUILogic();

  return (
    <Box sx={{ maxWidth: "730px" }}>
      <SupportingText text="Choose your department">
        <Spin
          isLoading={isLoadingEdit}
          sx={{ borderRadius: "16px" }}
          progressProps={{ sx: { color: "#ffffff" }, size: 20 }}
        >
          <Autocomplete
            options={dataGet?.list || []}
            value={department || null}
            filterSelectedOptions
            onOpen={onOpen}
            onChange={onChange}
            fullWidth
            isOptionEqualToValue={(option, value) =>
              option?.value === value?.value
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: isLoadingGet ? (
                    <InputAdornment position="start">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  ) : null,
                }}
                label="Department"
              />
            )}
          />
        </Spin>
      </SupportingText>
    </Box>
  );
}
