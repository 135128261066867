import { FormProvider } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHKPassword from "components/forms/rhk-password";
import useUILogic from "./logic";
import { StyledForm } from "./styles";

export default function ForgetPasswordReset({ setStep, savedData }) {
  const { methods, isLoading, onSubmit } = useUILogic({ setStep, savedData });

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography color="gray_iron.600" fontSize={18} mb={3}>
          Enter your new password
        </Typography>

        <RHKPassword
          name="new_password"
          label="New Password"
          rules={{ required: true, deps: ["confirm_new_password"] }}
          disabled={isLoading}
        />

        <RHKPassword
          name="confirm_new_password"
          label="Confirm New Password"
          rules={{
            required: true,
            validate: (value, formValues) =>
              value === formValues.new_password ||
              "* Confirm new password not matched !",
          }}
          disabled={isLoading}
          sx={{ mt: 5 }}
        />

        <Box mt={8} />

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          size="large"
        >
          Reset Password
        </LoadingButton>
      </StyledForm>
    </FormProvider>
  );
}
