export default function Menu(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          ".MuiMenu-paper": {
            borderRadius: 24,
            ul: {
              padding: "12px 0",
              li: {
                padding: "6px 36px 6px 20px",
                ".MuiListItemIcon-root": {
                  color: theme.palette.gray[400],
                },
                ".MuiListItemText-root": {
                  color: theme.palette.gray[600],
                },

                "&:not(:last-of-type)": {
                  ".MuiListItemIcon-root": {
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      width: "calc(100% - 40px)",
                      height: 1,
                      left: 20,
                      bottom: 0,
                      backgroundColor: theme.palette.gray_iron[100],
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}
