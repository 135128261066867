import { useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import useAxios from "hooks/useAxios";
import useModal from "hooks/useModal";
import { Panel_API_Routes } from "routes/path";

export default function useUILogic() {
  const { profileData, refetchProfile } = useOutletContext();

  // form
  const methods = useForm();
  const [formMode, setFormMode] = useState("add"); // add, edit
  const [openFormModal, toggleFormModal] = useModal();

  const onAddClick = () => {
    toggleFormModal();
    setFormMode("add");
    methods.reset({
      title: "",
      issuer: "",
      url: null,
    });
  };

  const onEditClick = (item) => {
    toggleFormModal();
    setFormMode("edit");
    methods.reset({
      id: item.id,
      title: item?.title,
      issuer: item?.issuer,
      url: item?.url ? { fileURL: item?.url, fileName: item?.url } : null,
    });
  };

  // delete
  const [openDeleteModal, toggleDeleteModal] = useModal();
  const { isLoading: isLoadingDelete, mutate: mutateDelete } = useAxios({
    url: Panel_API_Routes.profile.certificates.delete,
    method: "delete",
  });
  const onDelete = async () => {
    try {
      const data = {
        params: { id: openDeleteModal },
      };
      await mutateDelete({ data });
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      toggleDeleteModal();
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  return {
    certificates: profileData?.certificates,
    //
    methods,
    formMode,
    openFormModal,
    toggleFormModal,
    onAddClick,
    onEditClick,
    //
    openDeleteModal,
    toggleDeleteModal,
    onDelete,
    isLoadingDelete,
  };
}
