import { useState } from "react";

export default function useUILogic() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  };
}
