import { styled } from "@mui/material/styles";

export const StyledSkills = styled("div")(({ theme }) => ({
  ">div": {
    display: "flex",
    flexWrap: "wrap",
    ">div": {
      padding: "0 6px 0 12px",
      color: theme.palette.gray[700],
      borderRadius: 16,
      margin: "0 16px 16px 0",
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.palette.gray[300]}`,
      ">span:first-of-type": {
        position: "relative",
        top: 2,
        marginRight: 4,
      },
      ".MuiRating-root": {
        ".MuiRating-iconEmpty": {
          display: "none",
        },
        ".svg-box": {
          width: 14,
          height: 14,
        },
      },
      ">button": {
        color: theme.palette.gray[400],
        "&:disabled": {
          color: theme.palette.gray[200],
        },
        ".svg-box": {
          width: 15,
          height: 15,
        },
      },
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      ">div": {
        width: "fit-content",
        margin: "0 0 16px 0",
      },
    },
  },
}));
