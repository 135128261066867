import SvgBox from "components/svg-box";

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "[data-icon]": {
            width: 24,
            height: 24,
            border: `1px solid #1FAE83`,
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#1FAE83",
            ">span": {
              display: "none",
            },
          },
          "&.Mui-checked": {
            "[data-icon]": {
              backgroundColor: "#D4FAEF",
              ">span": {
                display: "block",
              },
            },
          },
        },
      },
      defaultProps: {
        icon: (
          <span data-icon>
            <SvgBox
              src="/assets/icons/common/ic_check.svg"
              sx={{ width: 14, height: 8 }}
            />
          </span>
        ),
        checkedIcon: (
          <span data-icon>
            <SvgBox
              src="/assets/icons/common/ic_check.svg"
              sx={{ width: 14, height: 8 }}
            />
          </span>
        ),
      },
    },
  };
}
