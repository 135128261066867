import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

export default function RHKCheckbox({ name, rules, onChange, ...other }) {
  const { control } = useFormContext();
  const {
    field: { ref, value, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: false });

  return (
    <FormControl>
      <FormControlLabel
        control={<Checkbox />}
        {...field}
        checked={value}
        onChange={(e, checked) => {
          field.onChange(checked);
          onChange?.(checked);
        }}
        inputRef={ref}
        {...other}
      />
      {error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}

RHKCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
};
