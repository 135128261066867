import { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";

export default function RHKSelect({
  name,
  rules,
  options,
  label,
  placeholder,
  startAdornment,
  ...other
}) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  return (
    <Autocomplete
      {...field}
      options={options}
      onChange={(e, newValue) => field.onChange(newValue)}
      fullWidth
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            startAdornment,
          }}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error ? error?.message || "* This field is required" : ""}
        />
      )}
      {...other}
    />
  );
}

RHKSelect.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.node,
  startAdornment: PropTypes.node,
};
