import SvgBox from "components/svg-box";

export default function Radio(theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          "[data-icon]": {
            width: 16,
            height: 16,
            border: `1px solid #1FAE83`,
            borderRadius: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#1FAE83",
            ">span": {
              display: "none",
            },
          },
          "&.Mui-checked": {
            "[data-icon]": {
              backgroundColor: "#D4FAEF",
              ">span": {
                display: "block",
              },
            },
          },
        },
      },
      defaultProps: {
        icon: (
          <span data-icon>
            <SvgBox
              src="/assets/icons/common/ic_check.svg"
              sx={{ width: 10, height: 6 }}
            />
          </span>
        ),
        checkedIcon: (
          <span data-icon>
            <SvgBox
              src="/assets/icons/common/ic_check.svg"
              sx={{ width: 10, height: 6 }}
            />
          </span>
        ),
      },
    },
  };
}
