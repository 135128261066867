import { useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Panel_API_Routes } from "routes/path";
import useAxios from "hooks/useAxios";

export default function useUILogic({ toggle, formMode, methods }) {
  const { profileData, refetchProfile } = useOutletContext();

  // add and edit
  const { isLoading: isLoadingAdd, mutate: mutateAdd } = useAxios({
    url: Panel_API_Routes.profile.educations.add,
    method: "post",
  });

  const { isLoading: isLoadingEdit, mutate: mutateEdit } = useAxios({
    url: Panel_API_Routes.profile.educations.edit,
    method: "put",
  });

  const onSubmit = async (params) => {
    try {
      const data = {
        params: {
          ...params,
          degree: params.degree?.value,
          status: params.status?.value,
          certificate_url: params.certificate_url?.fileURL,
        },
      };

      if (formMode === "add") {
        await mutateAdd({ data });
        enqueueSnackbar("Added successfully", { variant: "success" });
      } else {
        await mutateEdit({ data });
        enqueueSnackbar("Edited successfully", { variant: "success" });
      }
      toggle();
      refetchProfile({ callStartup: true });
    } catch (error) {}
  };

  // status and degree list
  const degreeList = useMemo(
    () => [
      {
        label: "Diploma",
        value: "Diploma",
      },
      {
        label: "Bachelor",
        value: "Bachelor",
      },
      {
        label: "Master",
        value: "Master",
      },
      {
        label: "PhD",
        value: "PhD",
      },
    ],
    []
  );
  const statusList = useMemo(
    () => [
      {
        label: "In-Complete",
        value: "In-Complete",
      },
      {
        label: "Graduated",
        value: "Graduated",
      },
      {
        label: "Currently Studying",
        value: "Currently Studying",
      },
    ],
    []
  );

  return {
    degreeList,
    statusList,
    showStudyingLevel: methods.watch("status")?.value === "Currently Studying",
    showFileUpload: methods.watch("status")?.value === "Graduated",
    //
    isLoading: isLoadingAdd || isLoadingEdit,
    onSubmit,
  };
}
