import PropTypes from "prop-types";
import { useFormContext, useController } from "react-hook-form";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { StyledWrapper } from "./styles";
import SvgBox from "components/svg-box";

export default function RHKSelect2({
  name,
  rules,
  label,
  placeholder,
  enableSearch = false,
  startAdornment,
  ...other
}) {
  const { control } = useFormContext();
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue: null });

  return (
    <StyledWrapper>
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <Autocomplete
        {...field}
        id={field.name}
        onChange={(e, newValue) => field.onChange(newValue)}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        noOptionsText="لاتوجد عناصر"
        popupIcon={<SvgBox src="/assets/icons/common/ic_arrow_down.svg" />}
        slotProps={{
          paper: { sx: { direction: "rtl", fontFamily: "Ubuntu Arabic" } },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            autoComplete="off"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
              readOnly: !enableSearch,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment,
            }}
            placeholder={placeholder}
            error={!!error}
            helperText={error ? error?.message || "هذا الحقل مطلوب" : ""}
          />
        )}
        {...other}
      />
    </StyledWrapper>
  );
}

RHKSelect2.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  enableSearch: PropTypes.bool,
};
