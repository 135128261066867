import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import SvgBox from "components/svg-box";

export default function Modal({
  open,
  handleClose,
  title,
  children,
  ...other
}) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth {...other}>
      <IconButton
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => handleClose()}
      >
        <SvgBox src="/assets/icons/common/ic_close.svg" />
      </IconButton>
      {open && (
        <>
          {title && (
            <DialogTitle sx={{ padding: "16px 48px 16px 24px" }}>
              {title}
            </DialogTitle>
          )}
          <DialogContent>{children}</DialogContent>
        </>
      )}
    </Dialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  children: PropTypes.node,
};
