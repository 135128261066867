import { styled, keyframes } from "@mui/material/styles";

export const StyledBlinkingBadge = styled("span")(({ theme, size }) => ({
  width: size,
  height: size,
  borderRadius: "100%",
  backgroundColor: theme.palette.primary[500],
  animation: `${blink} 1s infinite linear alternate`,
  "&::before": {
    content: '""',
    display: "inline-block",
    position: "absolute",
    width: size - 4,
    height: size - 4,
    top: 2,
    left: 2,
    borderRadius: "100%",
    backgroundColor: theme.palette.primary[500],
  },
  "&::after": {
    content: '""',
    display: "inline-block",
    position: "absolute",
    width: size - 8,
    height: size - 8,
    top: 4,
    left: 4,
    borderRadius: "100%",
    backgroundColor: theme.palette.primary[500],
  },
}));

const blink = keyframes`from{opacity:0.2}to{opacity:1}`;
